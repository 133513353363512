const en = {
  home: "Home",
  library: "Library",
  signrequest: "Sign Requests",
  challanges: "Challanges",
  community: "Community",
  hello: "Hello",
  welcome: "Welcome!",
  weolcomeBack: "Welcome back,",
  username: "Username",
  rememberme: "Remember me",
  forgotpassword: "Forgot Password?",
  signinwith: "or sign in with",
  donthaveaccount: "Don’t have an account?",
  create: "Create",
  addmusic: "Add Music",
  profile: "Profile",
  setting: "Settings",
  logout: "Logout",
  postatrack: "Upload a track ",
  track:"TRACK",
  postep: "POST AN EP",
  fresh: "Fresh",
  music: " music",
  oftoday: " of today",
  your: "your",
  lessons: " lessons",
  tofollow:" to follow",
  hot: "Hot",
  samples: " Samples",
  download: "Download",
  monthly: "Monthly",
  soon:"Soon",
  charts: " Charts",
  by: " by",
  newcontents: "New Contents",
  foryou: "for you",
  gotocharts: "Go to chart",
  seedjcommunity: "SEEDJ Community",
  librarysubheading: "Your advancement",
  tocontinue: "Your advancement",
  masterclass: "Masterclasses",
  producingtutorials: "Producing Tutorials",
  musictheory: "Music Theory",
  backstage: "Backstage",
  info: "Info",
  mymediakit: "My Media Kit",
  mytracksep: "My Tracks ",
  loginsuccess: "Login successfully",
  loginerror: "Please provide correct credentials",
  signupsuccess: "Signup successfully",
  resetpassword: "Reset Password",
  title: "Title",
  addtracktitle: "Name your track",
  describetrack: "Describe your track to let people know what it is... ",
  genre: "Genre",
  genreplaceholder: "Add keywords fro people to find your track",
  previewimage: "Preview Image",
  selectimagetrack: "Select a cover image for your track",
  preview: "Preview",
  postonprofile: "Post on your profile.",
  commentontrack: "Description",
  additionaldescription: "Write additional description",
  writedescription: "How is produced, how is your feeling about the track...",
  monitoring: "Monitoring",
  monitoringsubheading: "Add labels to make it easier to manage and find your track",
  monitoringplaceholder: "Tag here some labels you want to like collaborate with",
  lable: "Label",
  uploadeptrack: "Upload track",
  uploadepep: "Upload EP",
  uploadaudio: "Drag and drop your track here",
  "uploadfile": "or choose files to upload",
  drangdrophere: "or drag & drop here",
  next: "Next",
  fileuploadsuccess: "File uploaded successfully",
  trackuploadsuccess: "Track uploaded successfully",
  epuploadsuccess: "Episode uploaded successfully",
  profilesigned:"Profile Signed",
  profilereviewed:"Profile Reviewed",
  haveviewed:"have viewed",
  agency:"Agency",
  tracksigned:"Signed Tracks",
  trackreviewed:"Reviewed Tracks",
  similartoyours:"similar to yours",
  havelistened:"have listened",
  labels:"Labels",
  allcharts:"Browse all charts",
  newcharts:"New Charts",
  weeklycharts:"Your weekly chart",
  savedsamples:"Saved Samples",
  savedprofiles:"Saved Profiles",
  savedtracks:"Tracks",
  saved:"Saved ",
  addnewmedia:"Add New Media",
  photoorvideo:"photos",
  trackoreps:"Tracks",
  sendlink:"send link",
  ForgotPassword:"Forgot Password",
  exclusivesamples:"Samples",
  allsamples:"All samples",
  exclusiveloopsamples:"Exclusive loop & samples made for you",
  trendingnow:"TRENDING NOW",
  mostviewed:"MOST VIEWED",
  signin:"Sign In",
  signup:"Sign Up",
  startyourclimb:"Start your climb.",
  seedjisonline:"SEEDJ is online.",
  elctronicmusic:"The best of the electronic music industry at your disposal.",
  allinone:"All in one subscription. Cancel anytime.",
  checkoutemailplaceholder:"Insert your e-mail to proceed",
  getexclusive:"Get exclusive",
  contacts:"contacts",
  resources:"resources",
  and:"and",
  contents:"contents",
  everyday:"every day.",
  justfewsteps:"Just a few steps and you’re in.",
  createyourpassword:"Create your password.",
  step:"Step",
  of:"of",
  retry:"Go Back",
  almostdone:"Almost done!",
  checkyourmail:"Check your email and enter the verification code in this box below",
  addverificationcode:"Add verification code",
  securepayment:"Secure payment via PayPal",
  cancelanytimestep3:"Cancel anytime with no fees or hidden costs",
  monthlyupdate:"Monthly update of features and contents",
  sendmecode:"Send the code",
  resendsendmecode:"Resend the code",
  preordersummary:"Your checkout summary.",
  wanttoreward:"We want to reward all the first Early Adopters that are getting their access on SEEDJ Beta.",
  willgetpremium:"You will get the Premium monthly plan with 40% off, forever.",
  changeorcancel:"Cancel anytime with no extra fees.",
  seatsavailable500:"500 seats available.",
  onepersoneverymembership:"Only one person for every membership. Your profile will be reviewed from labels and booking agencies.",
  receiveemail:"You can instantly login the platform after your checkout.",
  weeklyupdates:"You will receive weekly updates about new partners, contents and features.",
  unlimited:"Unlimited",
  limitbasicplan:"(Limit for the Basic plan:",
  masterclassandtutorials:"Masterclasses & Tutorials",
  course:"COURSE",
  courses:" Courses",
  librarySidePanelReminderTitle: "Your reminder was set succesfully.",
  librarySidePanelUnReminderTitle: "Your reminder was unset succesfully.",
  librarySidePanelReminderDescription: "We will send you an email when the course is released",
  courses5:"5 courses )",
  trackuploads:"Track Uploads",
  tracks3:"3 tracks )",
  sampleslibrary:"Samples Library",
  samples30:"30 samples )",
  dailycharts:"Charts Library",
  charts10:"10 charts )",
  labelrequest:"Labels Request",
  request1:"1 request )",
  bookingrequest:"Agency Request",
  premium:"Premium*",
  yourlastchance:"*It’s your last chance to get our premium features at this price.",
  acceptcreditanddebit:"We accept credit or debit cards. Your payment will be elaborated via PayPal, Debit or Credit Card.",
  seatreserved10mins:"Your seat will be reserved for ",
  minutes:"minutes",
  gainaccess:"You will receive a confirmation e-mail right after your pre-order.",
  subscriptionrenewedmonthly:"Your subscription (€29) will be renewed every 30 days from today.",
  cancelanytime:"Your payment will be elaborated through PayPal and you can cancel anytime.",
  receiveconfirmation:"You will receive a confirmation e-mail ",
  afterpreorder:"right after your pre-order.",
  preordernow:"Subscribe now",
  paysecurelypaypal:"Setup your payment method.",
  preorderconfirmed:"You can start your SEEDJ experience and get all our unlimited features at this monthly price, forever.",
  getaspoiler:"Login",
  done:"Done !",
  trackuploadshint:"Upload your track that will be listened and reviewed by our labels partners filtered on your micro-genre.",
  masterclassandtutorialshint:"watch 150 hours of content recorded by best DJ's and Producers. We will add 30 hours every month.",
  sampleslibraryhint:"Download +600 samples ready to use for your production we add 240 samples every month.",
  dailychartshint:"Get access to hundreds of charts in partnership with media and DJs all over the world. Update daily.",
  labelrequesthint:"Receive and accept requests from labels that have listened your tracks and do releases.",
  bookingrequesthint:"Receive and accept requests from booking agencies to join their roster. Keep updated your profile :-).",
  emailvalidsuccess:"Email registered sucessfully.",
  contactstip:"Connect directly with more than 300 partners including booking agencies, record labels and international DJs.",
  emailvaliderror:"Please enter a valid e-mail.",
  allfieldmandatory:"All fields mandatory.",
  validpassword:"Please enter valid password.",
  validotp:"Please enter valid otp.",
  exclusive:"Exclusive",
  passwordtip:"Min. 8 characters ",
  passwordtip1:"1 uppercase letter ",
  passwordtip2:"1 Number ",
  passwordtip3:"1 lowercase letter ",
  valid:"Valid",
  notvalid:"Not valid",
  month1free:"1 month for free",
  month1tip:"We will activate your subscription on 30th August and you will start to pay the monthly fee on 30th September 2021.",
  premiumplan:"Premium plan",
  yourtimeup:"Your time is up try again.",
  notmatching:"Not matching",
  newmasterclasstip:"Watch always new Masterclasses and Tutorials from your favourite DJs and Producers.",
  resendhinttip:"If you can't get the code check your spam or resend the verification code in your inbox.",
  resourcetip:"Get hundreds of exclusive charts and samples filtered on your genre updated daily.",
  slotnotavailable:"No more slots availiable right now.",
  matching:"Matching",
  entervaliddetails:"Please enetr valid details",
  loadertext:'One moment please...',
  enterpassword:"Enter your password",
  confirmpassword:"Confirm your password",
  login:"Login",
  subgenre:"Subgenre",
  uploadimage:"Upload image",
  upload:"Upload",
  filename:"File Name",
  duration:"Duration",
  yourtrackbeenuploaded:"You've uploaded your track!",
  yourepbeenuploaded:"Your EP has been uploaded.",
  uploadnextrack:"Upload more music",
  getmetodashboard:"Go to home",
  uploadproperresolutionimage:"Please select a image having a resolution atleast 400*400 pixels.",
  uploadcoverimage:"Select a image with minimum resolution of 400*400 pixels",
  supportedprofiles:"Supported Profiles",
  thankyou:"Thank you!",
  profilesetup:"Your payment was successful, now it’s time to setup your profile.",
  yourstagename:"Your stage name",
  soundcloudlink:"Soundcloud Link",
  age:"Age",
  residentadvisorlink:"Resident Advisor Link",
  entercity:"Enter city",
  city:"City",
  country:"Country",
  socialmedialink:"Social Media Link (FB/IG)",
  youraffiliationlink:"Your Affiliation Link",
  maingenre:"Main genre",
  uploadprofileimage:"Upload Profile image",
  profileupdated:"Profile updated successfully.",
  fillrequiredfields:"Please fill all the required fields.",
  fillyourbio:"Fill your bio",
  startonboarding:"Start your onboarding",
  righttalent:"Fill these fields that will help us to find the right talents for your ",
  uploadplogo:"Upload logo",
  skippedtracks:"Skipped tracks",
  saveforlater:"Save for later",
  sendrequest:" Send a request",
  alreadysent:" Request already sent",
  alreadysaved:"Track saved",
  managername:"Enter manager name",
  aboutlesson:"About this class",
  aboutcourse:"About this course",
  addtofavourites:"Add to my favourites",
  removefavouriteS:"Remove from favourites",
  becomingaproffesional:"Becoming a professional",
  myearlycarrer:"My Early Carrer",
  startacourse:"...or check new courses  ",
  starttrackrevision:"START TRACK REVISION",
  startyourscouting:"START YOUR SCOUTING",
  markassigned:"Mark as Signed",
  alreadysigned:"Already Signed",
  signed:"Signed",
  maxsignedtracks:"Already Signed 3 tracks",
  profilesaved:"Profile saved succesfully.",
  tracksaved:"Track saved successfully.",
  requestsent:"Request sent successfully.",
  enjoynewunreleased:"Enjoy new unreleased tracks from other users.",
  playthistrack:"PLAY THIS TRACK",
  learnnewskills:"Learn new skills from recognised tutors and build your own music identity.",
  whatisexcepted:"What is expected to see in SEEDJ in the next months?",
  djing:"DJing",
  producing:"Producing",
  musicbusiness:"Music Business",
  avialablecourses:"Courses for you",
  upcomingcourses:"Upcoming Courses",
  startyour:"Start your ",
  session:"session.",
  inthisclass:"In this course:",
  audioresources:"Audio resources ready-to-use for your productions.",
  ourcharts:"Our charts selection to get always new music for your sets.",
  trackremoved:"Track removed successfully.",
  linkselected:"Link selected track:",
  marksignedtext:"In order to promote this DJ on your profile, you will need to mark the track as signed. To do it, you just need to paste the link of the trak in this text box!",
  addnewmusic:"Add New Music",
  account:"Account",
  subscriptionbilling:"Subscription and Billing",
  uploadmedia:"Upload Media",
  bio:"Bio",
  billingaddress:"Billing Address",
  downloadalldocuments:"Download all payout documents",
  unsucsribe:"Unsubscribe",
  tocontinueclass:"Continue watching",
  changemail:"Change email",
  changepassword:"Change password",
  oldemail:"Old email",
  newemail:"New Email",
  oldpassword:"Old password",
  newpassword:"New password",
  confirmnewpassword:"Confirm new password",
  resetpasswordtoast:"password reset succesfully",
  update:"Update",
  save:"Save",
  cancel:"Cancel",
  email:"Email",
  setupyourbilling:"Setup your billing.",
  enjoy2months:"Enjoy your first 2 months for free.",
  addpaymentmethos:"Add your main payment method.",
  renewalsubscription:"Your first monthly subscription renewal will be on October 30, 2021 and the amount will be €19.",
  gotodashboard:"Go to dashboard",
  findgenre:"Find a genre...",
  findsubgenre:"Find a subgenre...",
  socialmedialinksetting:"Instagram Link",
  playedthistrack:"Played this track",
  supporttrack:"support this track",
  selectcountry:"Select country first...",
  previoustrack:"Previous track",
  previousclass:"Previous class",
  nexttrack:"Next track",
  nextclass:"Next class",
  loop:"Loop",
  reload:"Reload",
  noconversation:"No conversation is selected or started yet...",
  unsuscribeanddelete:"Unsubscribe and delete account",
  unsuscribetext:'Clicking the button "Confirm" below you are losing all your benefits and the opportunity to get your Premium monthly subscription at this price forever. (It will never be at this price anymore). From now your account will be deactivated.',
  keepmyaccount:"Back to SEEDJ",
  proceed:"Proceed",
  cancelpartnership:"Cancel partnership",
  play:"Play",
  pause:"Pause",
  editpassword:"Edit password",
  nohistory:"You don't have a history yet, start a course!",
  nofavourites:"You don't have any favorites yet, start a course!",
  nomedia:"This DJ/Producer has no media.",
  notracks:"This DJ/Producer has no tracks.",
  comingsoon:"Coming soon",
  nosavedtracks:" You have not saved any track.",
  nosavedprofiles:" You have not saved any profile.",
  changedebitcredit:"Change Debit/Credit card",
  deletetrack:"Delete track",
  deletemedia:"Delete media",
  gotohome:"Go to Home",
  hopetooseeyou:"We hope to see you soon again.",
  subscribeagain:"It seems that you has been unsubscribed. To login you can subscribe again from ",
  uploadinprogress:"Upload in progress please wait...",
  feedback:"Feedback",
  submit:"Submit",
  support:"Support",
  supportheading:"Provide your valuable feedback or if you have any query our team solve it and reach you soon .",
  newsimilarcharts:"New similar charts",
  nosearch:"No results found",
  nofavouritechart:"You don't have any favourite chart, add it!",
  youmightlike:"You might like... ",
  nodata:"No samples matching your quey...",
  homepage: "Homepage",
  try:'Try it now.',
  seedj2:'Seedj 2.0 is now available.',
  freshnewmusic: "Fresh new music",
  likes: "likes",
  ops: "OOPS!",
  mobileErrorFirstPhrase:"We are working on a new mobile version.",
  mobileErrorSecondPhrase:"In the meantime, you can login from your laptop.",
  welcome_courses: "Welcome to Courses!",
  library_courses_hours_pre: "Follow more than",
  library_courses_hours: "150 hours of courses",
  library_courses_text: "with your favourite tutors",
  library_courses_months: "when you want.",
  samples_header_subtitle: "Trending",
  home_label_review_music: "Start reviewing some fresh music.",
  home_label_title: "Dashboard",
  home_label_reviewed_track: "Reviewed Tracks",
  home_label_signed_track: "Signed Tracks",
  home_label_remaining_track: "Remaining Tracks",
  home_label_subheading: "Tracks status",
  home_label_inner_menu_1: "Send Request",
  home_label_inner_menu_2: "Save for later",
  home_label_inner_menu_2_variant: "Unsave",
  home_label_inner_menu_3: "Not interesting",
  home_label_inner_menu_4: "Delete from this tab",
  home_agency_review_talent: "Start your scouting for new talents.",
  home_agency_reviewed_profile: "Reviewed Profiles",
  home_agency_signed_profile: "Signed Profiles",
  home_agency_remaining_profile: "Remaining Profiles",
  sign_in_modal_message_request_title: "Message request.",
  sign_in_modal_message_request_description: "Accept to be able to chat with them. Cancel to reject the request",
  sign_in_modal_message_request_cancel: "Cancel",
  sign_in_modal_message_request_accept: "Accept",
  sign_in_modal_message_delete_title : "Delete chat.",
  sign_in_modal_message_delete_description : "Deleting this conversation cannot be undone.",
  sign_in_modal_message_delete_accept : "Delete chat",
  sign_in_modal_message_delete_cancel : "Cancel",
};
export default en;
