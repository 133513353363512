import react, {useEffect, useState} from 'react'

export default () => {
    const [deviceType, setDeviceType] = useState('')
    useEffect(() => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            setDeviceType("tablet")
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            setDeviceType("mobile")
        }
        else {
            setDeviceType("desktop")
        }
    },[])
    return [deviceType]
};
