import i18n from "i18n-js";
import en from "./english";
import sp from "./sp";
import it from "./it";
i18n.fallbacks = true;
i18n.translations = {
  en,
  sp,
  it
};

export const setLocale = (locale) => {
  i18n.locale = locale;
};

export const getCurrentLocale = () => i18n.locale;

export default i18n.t.bind(i18n);