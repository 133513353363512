import './landingpage.scss'
import '../../screen/checkout2.0/planSelection.scss'
import mixmagLogo from '../../assets/images/mixmag-logo.png'
import fazeLogo from '../../assets/images/faze-logo.png'
import grooveLogo from '../../assets/images/groove-logo.png'
import djLogo from '../../assets/images/dj-logo.png'
import seedjLogo from '../../assets/images/seedj-landing-page-logo.png'
import macbook from '../../assets/images/macbook.png'
import trustpilotStars from '../../assets/images/trustpilot-stars.png'
import trustpilotFourStars from '../../assets/images/trustpilot-4-stars.png'
import crossIcon from '../../assets/images/cross-icon.png'
import minusIcon from '../../assets/images/minus-icon.png'
import oldSeedj from '../../assets/images/old-seedj.png'
import currentSeedj from '../../assets/images/current-seedj.png'
import newSeedj from '../../assets/images/new-seedj.png'
import chatSeedj from '../../assets/images/chat-seedj.png'
import samplesSeedj from '../../assets/images/samples-seedj.png'
import chartsSeedj from '../../assets/images/charts-seedj.png'
import musicNote from '../../assets/images/music-note.png'
import forum from '../../assets/images/forum.png'
import videoLibrary from '../../assets/images/video-library.png'
import electricBolt from '../../assets/images/electric-bolt.png'
import discoverTuner from '../../assets/images/discover-tune.png'
import twoUsers from '../../assets/images/2-users.png'
import filternNone from '../../assets/images/filter-none.png'
import inventoryTwo from '../../assets/images/inventory-2.png'
import cardMembership from '../../assets/images/card-membership.png'
import groups from '../../assets/images/groups.png'
import chevronLeft from '../../assets/images/chevron-left.png'
import chevronRight from '../../assets/images/chevron-right.png'
import militaryTech from '../../assets/images/military-tech.png'
import dashboardSeedj from '../../assets/images/dashboard-seedj.png'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import { useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions'
import { useNavigate } from 'react-router-dom'

import alarico from '../../assets/images/landingpage/alarico.jpg'
import anetha from '../../assets/images/landingpage/anetha.jpg'
import chlaer from '../../assets/images/landingpage/chlaer.jpg'
import spfdj from '../../assets/images/landingpage/spfdj.jpg'
import ilarioAlicante from '../../assets/images/landingpage/ilario-alicante.jpg'
import nastia from '../../assets/images/landingpage/nastia.jpg'
import perc from '../../assets/images/landingpage/perc.jpg'
import trym from '../../assets/images/landingpage/trym.jpg'
import vilAndCravo from '../../assets/images/landingpage/vil-and-cravo.jpg'
import adiel from '../../assets/images/landingpage/adiel.jpg'
import francoisX from '../../assets/images/landingpage/francois-x.jpg'
import zippo from '../../assets/images/landingpage/zippo.jpg'
import anastasiaKristensen from '../../assets/images/landingpage/anastasia-kristensen.jpg'
import nicoMoreno from '../../assets/images/landingpage/nico-moreno.jpg'
import alignment from '../../assets/images/landingpage/alignment.jpg'
import hadone from '../../assets/images/landingpage/hadone.jpg'
import quelza from '../../assets/images/landingpage/quelza.jpg'
import scalameriya from '../../assets/images/landingpage/scalameriya.jpg'
import uvb from '../../assets/images/landingpage/uvb.jpg'
import lucaAgnelli from '../../assets/images/landingpage/luca-agnelli.jpg'
import cynthiaSpiering from '../../assets/images/landingpage/cynthia-spiering.jpg'
import reemco from '../../assets/images/landingpage/reemco.jpg'
import ayarcana from '../../assets/images/landingpage/ayarcana.jpg'
import geatanoParisio from '../../assets/images/landingpage/gaetano-parisio.jpg'
import volruptus from '../../assets/images/landingpage/volruptus.jpg'
import lillyPalmer from '../../assets/images/landingpage/lilly-palmer.jpg'
import otta from '../../assets/images/landingpage/otta.jpg'
import and from '../../assets/images/landingpage/and.jpg'
import manniDee from '../../assets/images/landingpage/manni-dee.jpg'
import artik from '../../assets/images/landingpage/artik.jpg'
import tred from '../../assets/images/landingpage/tred.jpg'
import raffaeleAttanasio from '../../assets/images/landingpage/raffaele-attanasio.jpg'
import joeFarr from '../../assets/images/landingpage/joe-farr.jpg'
import arthurRobert from '../../assets/images/landingpage/arthur-robert.jpg'
import casualTreatment from '../../assets/images/landingpage/casual-treatment.jpg'
import julianMuller from '../../assets/images/landingpage/julian-muller.jpg'
import caiva from '../../assets/images/landingpage/caiva.jpg'
import schacke from '../../assets/images/landingpage/schacke.jpg'
import blicz from '../../assets/images/landingpage/blicz.jpg'
import bec from '../../assets/images/landingpage/bec.jpg'
import djPlantTexture from '../../assets/images/landingpage/dj-plant-texture.jpg'
import noName from '../../assets/images/landingpage/no-name.jpg'
import coyu from '../../assets/images/landingpage/coyu.jpg'
import claudioPRC from '../../assets/images/landingpage/claudio-prc.jpg'
import basswell from '../../assets/images/landingpage/basswell.jpg'
import katatonicSilentio from '../../assets/images/landingpage/katatonic-silentio.jpg'
import macDeclos from '../../assets/images/landingpage/mac-declos.jpg'
import psyk from '../../assets/images/landingpage/psyk.jpg'
import shlomo from '../../assets/images/landingpage/shlomo.jpg'
import mattiaTrani from '../../assets/images/landingpage/mattia-trani.jpg'
import elementOne from '../../assets/images/brandidentity/element-one.png'
import elementTwo from '../../assets/images/brandidentity/element-two.png'
import elementThree from '../../assets/images/brandidentity/element-three.png'
import elementFour from '../../assets/images/brandidentity/element-four.png'
import elementFive from '../../assets/images/brandidentity/element-five.png'
import elementSix from '../../assets/images/brandidentity/element-six.png'

import daze from '../../assets/images/talkingaboutus/daze.jpg'
import dj from '../../assets/images/talkingaboutus/dj.jpg'
import djtechtools from '../../assets/images/talkingaboutus/djtechtools.jpg'
import elite from '../../assets/images/talkingaboutus/elite.jpg'
import faze from '../../assets/images/talkingaboutus/faze.jpg'
import eturia from '../../assets/images/talkingaboutus/eturia.jpg'
import groove from '../../assets/images/talkingaboutus/groove.jpg'
import mixmag from '../../assets/images/talkingaboutus/mixmag.jpg'
import musictech from '../../assets/images/talkingaboutus/musictech.jpg'
import parallel from '../../assets/images/talkingaboutus/parallel.jpg'
import perctrax from '../../assets/images/talkingaboutus/perctrax.jpg'
import playful from '../../assets/images/talkingaboutus/playful.jpg'
import reflex from '../../assets/images/talkingaboutus/reflex.jpg'
import sowhul from '../../assets/images/talkingaboutus/sowhul.jpg'
import suara from '../../assets/images/talkingaboutus/suara.jpg'
import xxlab from '../../assets/images/talkingaboutus/xxlab.jpg'
import yam from '../../assets/images/talkingaboutus/yam.jpg'

import electricBoltTwo from '../../assets/images/electric_bolt.png'
import attachMoney from '../../assets/images/attach_money.png'
import landingGif from '../../assets/images/landing-gif.gif'
import landingVideo from '../../assets/images/landing-video.webm'

import checked from "../../assets/images/checkout/checked_simple.svg";
import unchecked from "../../assets/images/checkout/unchecked_simple.svg";
import tempestIcon from "../../assets/images/checkout/tempestIcon.svg";
import useDeviceAgent from '../../utils/hooks/useDeviceAgent'
import {useQuery} from "react-query";
import {getMethodOpen} from "../../utils/apis/services/api";
import urls from "../../utils/apis/services/apiurls.json";
import {getToken} from "../../utils/common";

const FAQ_STATE = [
  {
    title: "What’s Seedj?",
    content: <p className='top-border'>Seedj is an online platform created for electronic music DJs & Producers that want to get their careers in music industry. With Seedj you will get new masterclasses, tutorials, samples and charts from the best exponents of the electronic music industry every month. You can also upload your tracks, create your profile and accept or decline requests by Labels and Booking Agencies.</p>
  },
  {
    title: "What's included with my subscription?",
    content: <p className='top-border'>With the Black Friday offer you are purchasing a Premium subscription with a special price.

    It means that you will get unlimited features with no limits of usage.

    In details:

    ▪ Upload unlimited tracks

    ▪ Open and accept unlimited requests from Labels and Agencies

    ▪ Watch unlimited hours of contents (Masterclasses, Tutorials…)

    ▪ Download unlimited Samples

    ▪ Open unlimited Charts</p>
  },
  {
    title: "Why should I subscribe now?",
    content: <>
      <p className='top-border'>You will have the Premium plan (standard price: 49€/month) with a special price, you will be saving a lot of money and get our features and course library unlimited.</p>
    </>
  },
  {
    title: "How can I get new contacts with SEEDJ?",
    content: <>
      <p className='top-border'>You should always keep your profile updated with new tracks, photos and videos to allow Labels & Agencies to see your profile on the top of their database.</p>
      <p>They will contact you to release new tracks (Labels) or join their roster and book new dates (Agencies).</p>
    </>
  },
  {
    title: "Which contents will I get?",
    content: <>
      <p className='top-border'>You will get exclusive Masterclasses, Tutorials and Interviews about: DJing and Producing Techniques, Sound Design, Mixing & Mastering, Conceptual Learning, Signal Audio Processing, Label Management, Artist Promotion and many more topics.</p>
      <p>At your first login you will get 150 hours of contents that we will update with 3 more courses every months.</p>
    </>
  },
  {
    title: "When will I start paying my subscription?",
    content: <>
      <p className='top-border'>After purchasing your subscription, you will instantly gain access to SEEDJ and you will start paying fee based on the cycle that you have chosen in the first checkout (3 or 6 months).</p>
      <p>Your subscription price will be fixed forever. (no hidden fees or amount increase).</p>
    </>
  },
  {
    title: "How often will you update the platform?",
    content: <>
      <p className='top-border'>We will update contents and features every month.</p>
    </>
  },
  {
    title: "What's Seedj 2.0?",
    content: <>
      <p className='top-border'>Seedj 2.0 will be the new version of our platform that we will release in Early 2023. It will include some main integrations and new features that will improve your experience in Seedj.</p>
      <p>If you buy now the 6-months bundle, the new version will be included in your first cycle.</p>
    </>
  }
]

const NEWS_STATE = [
  {
    title: "Personalized experience",
    content: <p className='top-border'>We will introduce personalized "Paths" with an interactive onboarding to choose your start point and a personalized dashboard to track your achievements in the industry.</p>
  },
  {
    title: "Contests",
    content: <p className='top-border'>Competitions partnering with venues, record labels and medias giving you the opportunity to really put yourself out there and be seen by other artists and entities.</p>
  },
  {
    title: "Demo submission",
    content: <p className='top-border'>Upload your unreleased tracks to get played by recognised artists in their gigs all over the world and take your productions out your of your room.</p>
  },
  {
    title: "Talent pool",
    content: <p className='top-border'>You will be introduced by our team to high-tier labels, venues, medias and booking agencies to make high valuable new connections in the industry.</p>
  },
  {
    title: "Plugins & Softwares",
    content: <p className='top-border'>We will provide many paid plugins & softwares that will add much more value to your subscription.</p>
  },
  {
    title: "And more...",
    content: <p className='top-border'>We will provide much more content (courses, masterclasses, samples and charts), new features (timer for learning time, profile insights, email notifications for selected courses, etc) and a complete new look.</p>
  },
]

const LandingPage = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate()
  const [overview, setOverview] = useState(oldSeedj)

  const getPaymentPlans = async () => getMethodOpen(urls.paymentplans, getToken())

  const {data: paymentPlans} = useQuery(['paypalPlans'], getPaymentPlans)
  const [activePlan, setActivePlan] = useState(null)
  const [deviceType] = useDeviceAgent();

  const onSetActivePlan = id => () => {
    setActivePlan(id)
  }
  const [key, setKey] = useState('')
  const [countdown, setCountdown] = useState({days: '00', hours: '00', minutes: '00', seconds: '00'})
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    const countdown = localStorage.getItem('countdown')

    // check if timer exists
    if (!countdown) {
      const timer = Date.now() + (7 * 24 * 60 * 60 * 1000)
      localStorage.setItem('countdown', timer)
      setTimer(timer)
    } else {
      // check if timer is in the past then reset it
      if (new Date(parseInt(countdown)) - Date.parse(new Date()) < 0) {
        const timer = Date.now() + (7 * 24 * 60 * 60 * 1000)
        localStorage.setItem('countdown', timer)
        setTimer(timer)
      // restore timer
      } else {
        setTimer(new Date(parseInt(countdown)))
      }
    }
  }, [])

  const timeRemaining = (endtime) => {
    const t = endtime - Date.parse(new Date());
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {days, hours, minutes, seconds};
  }

  const runClock = (endtime) => {
    const updateClock = () => {
      const t = timeRemaining(endtime);
      if (t.minutes === -1) {
          clearInterval(timeinterval);
          window.location.reload();
      }
      setCountdown(t)
    }

    updateClock(); // run function once at first to avoid delay
    const timeinterval = setInterval(updateClock, 1000);
  }
// console.log(timer)
// console.log(countdown)
  useEffect(() => {
    if (timer) runClock(timer)
  }, [timer])

  return (
    <div className='wrapper'>
      <div className="landing">
        <div className='overlay'>
        <div className='heading'>
            <img src={seedjLogo} alt='seedj logo' />
            <div className='spacer' />
            {width > 480 && (
              <>
                <div onClick={() => navigate('/login')} style={{ cursor: 'pointer', fontWeight: 560 }}><span>Log in</span></div>
                <div className='button--white' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
              </>
            )}
          </div>
          <div className='center'>
            <h2>Believing in music.</h2>
            <p>In Seedj you can find courses, contacts and resources necessary to accelerate your path in the electronic music industry. </p>
            <p>Black Friday Promo: You can buy now your subscription at reduced price.</p>
            <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
          </div>
          <div className='bottom-wrapper'>
            <h4>Talking about us</h4>
            <div className='logos-wrapper'>
              <img src={grooveLogo} alt='logo' />
              <img src={fazeLogo} alt='logo' />
              <img src={mixmagLogo} alt='logo' />
              <img src={djLogo} alt='logo' />
            </div>
          </div>
        </div>
      </div>
      <div className='accelerate-career'>
        <img className='brand-identity' src={elementOne} alt=''/>
        <div className='left-wrapper'>
          <h3>We support the growth of artists in the electronic music industry.</h3>
          <p>In a single platform we have enclosed the services necessary for professional growth as a DJ/Producer.</p>
          <p>Watch new video contents featuring the best exponents of the underground industry, connect instantly with hundreds of labels and booking agencies, get exclusive sample libraries and charts (and many more features are coming very soon).</p>
          <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
        </div>
        {width > 480
        ? <video
            playsInline
            autoPlay
            muted
            loop
            className='videoTag'
            src={landingVideo}
          />
        : <img src={macbook} alt=''/>
      }
      </div>
      <div className='benefits'>
        {width > 768 && <img className='brand-identity-one' src={elementThree} alt=''/>}
        {width > 768 && <img className='brand-identity-two' src={elementThree} alt=''/>}
        <div className='benefits-top'>
          <h3>What's included in Seedj?</h3>
          <p>A variety of benefits are included in one subscription. We truly believe that consistency and discipline create stunning artists.</p>
          <p>And it costs less than <b>€1</b> a day.</p>
          <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
        </div>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          showIndicators={true}
          swipeable
          autoPlay
          interval={10000}
          infiniteLoop
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={30}
        >
          <div className='benefits-tiles-wrapper'>
            <div className='benefits-tile'>
              <img src={videoLibrary} alt="" />
              <h5 className='benefits-tile-title'>Courses</h5>
              <h6 className='benefits-tile-subtitle'>+3 courses every month</h6>
              <p className='benefits-tile-text'>Unlimited access to +150hrs of contents with the best exponents of electronic music industry.</p>
            </div>
            <div className='benefits-tile'>
              <img src={forum} alt="" />
              <h5 className='benefits-tile-title'>In-platform chat</h5>
              <h6 className='benefits-tile-subtitle'>Get in touch with record labels and agencies.</h6>
              <p className='benefits-tile-text'>Our chat makes the communication process with our partners very simple.</p>
            </div>
            <div className='benefits-tile'>
              <img src={electricBolt} alt="" />
              <h5 className='benefits-tile-title'>Seedj 2.0 included</h5>
              <h6 className='benefits-tile-subtitle'>Out soon</h6>
              <p className='benefits-tile-text'>New dashboard, personalised paths, contests, talent pools and much more.</p>
            </div>
          </div>
          <div className='benefits-tiles-wrapper'>
            <div className='benefits-tile'>
              <img src={inventoryTwo} alt="discover" />
              <h5 className='benefits-tile-title'>Samples</h5>
              <h6 className='benefits-tile-subtitle'>Massive library of samples</h6>
              <p className='benefits-tile-text'>You’ll get access to our massive library of samples filtered by micro-genre, bpm, structure and key.</p>
            </div>
            <div className='benefits-tile'>
              <img src={filternNone} alt="discover" />
              <h5 className='benefits-tile-title'>Daily charts</h5>
              <h6 className='benefits-tile-subtitle'>+120 per month</h6>
              <p className='benefits-tile-text'>Filtered by your micro-genre, compiling exclusive tracks and keeping you up to date with latests trends.</p>
            </div>
            <div className='benefits-tile'>
              <img src={twoUsers} alt="discover" />
              <h5 className='benefits-tile-title'>Private tutoring</h5>
              <h6 className='benefits-tile-subtitle'>Book a session with our e-learning team</h6>
              <p className='benefits-tile-text'>Book free tutoring sessions to clarify your doubts about specific courses or solve issues with the platform. </p>
            </div>
          </div>
          <div className='benefits-tiles-wrapper'>
            <div className='benefits-tile'>
              <img src={groups} alt="discover" />
              <h5 className='benefits-tile-title'>Community</h5>
              <h6 className='benefits-tile-subtitle'>Counting on over 3k artists</h6>
              <p className='benefits-tile-text'>You’ll be part of a big community full of artist, labels and agencies you can follow and connect with.</p>
            </div>
            <div className='benefits-tile'>
              <img src={militaryTech} alt="discover" />
              <h5 className='benefits-tile-title'>Contests and Demos</h5>
              <h6 className='benefits-tile-subtitle'>Out soon</h6>
              <p className='benefits-tile-text'>Participate in competitions to get new collaborations with artists, venues and record labels.</p>
            </div>
            <div className='benefits-tile'>
              <img src={cardMembership} alt="discover" />
              <h5 className='benefits-tile-title'>Vertical Courses</h5>
              <h6 className='benefits-tile-subtitle'>Out soon</h6>
              <p className='benefits-tile-text'>Vertical courses are designed to differentiate you from your other colleagues in the music industry, giving you a unique advantage in your sound design portfolio.</p>
            </div>
          </div>
        </Carousel>
      </div>
      <div className='courses-library'>
        <img className='brand-identity-one' src={elementTwo} alt=''/>
        <img className='brand-identity-two' src={elementTwo} alt=''/>
        <h3>Courses Library</h3>
        <div className='instructions'>
          <p>Every month we enrich our library with new contents in collaboration with the main exponents of the sector.</p>
          <p>All our courses are available in high-quality and subtitled in English, Spanish, French, German and Italian.</p>
          <p>Swipe to see some of our tutors.</p>
        </div>
        {width >= 950 ? (
          <div className='white-arrows'>
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              showIndicators={true}
              swipeable
              autoPlay
              interval={10000}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
            >
              {
                COURSES_LIBRARY.map((page, index) => {
                  return (
                    <div className='library-page'>
                      <div className='library-wrapper'>
                        <div className='first-row'>
                          <div className='image-wrapper positioner'>
                            <img src={page[0].img} alt="" />
                            <LibraryItemMetaData page={page[0]}/>
                          </div>
                          <div className='image-wrapper positioner'>
                            <img src={page[1].img} alt="" />
                            <LibraryItemMetaData page={page[1]}/>
                          </div>
                          <div className='small-column-wrapper'>
                            <div className='small-image-wrapper positioner'>
                              <img src={page[2].img} alt="" />
                              <LibraryItemMetaData page={page[2]}/>
                            </div>
                            <div className='small-image-wrapper positioner'>
                              <img src={page[3].img} alt="" />
                              <LibraryItemMetaData page={page[3]}/>
                            </div>
                          </div>
                          <div className='small-column-wrapper'>
                            <div className='small-image-wrapper positioner'>
                              <img src={page[4].img} alt="" />
                              <LibraryItemMetaData page={page[4]}/>
                            </div>
                            <div className='small-image-wrapper positioner'>
                              <img src={page[5].img} alt="" />
                              <LibraryItemMetaData page={page[5]}/>
                            </div>
                          </div>
                        </div>
                        <div className='second-row'>
                          <div className='first-element image-wrapper positioner' >
                            <img src={page[6].img} alt="" />
                            <LibraryItemMetaData page={page[6]}/>
                          </div>
                          <div className='image-wrapper stretch positioner'>
                            <img src={page[7].img} alt="" />
                            <LibraryItemMetaData page={page[7]}/>
                          </div>
                          <div className='image-wrapper stretch positioner'>
                            <img src={page[8].img} alt="" />
                            <LibraryItemMetaData page={page[8]}/>
                          </div>
                          <div className='image-wrapper stretch positioner'>
                            <img src={page[9].img} alt="" />
                            <LibraryItemMetaData page={page[9]}/>
                          </div>
                        </div>
                      {index === 4 && <h2>And many more available in our platform</h2>}
                      </div>
                    </div>
                  )
                })
              }
            </Carousel>
          </div>
        ) : (
          <div className='white-arrows'>
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              showIndicators={false}
              swipeable
              autoPlay
              interval={5000}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
            >
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[0][0].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][0]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[0][2].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][2]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[0][1].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][1]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[0][3].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][3]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[0][7].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][7]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[0][4].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][4]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[0][8].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][8]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[0][5].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][5]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[0][9].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][9]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[0][6].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[0][6]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[1][0].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][0]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[1][2].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][2]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[1][1].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][1]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[1][3].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][3]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[1][7].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][7]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[1][4].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][4]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[1][8].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][8]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[1][5].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][5]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[1][9].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][9]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[1][6].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[1][6]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[2][0].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][0]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[2][2].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][2]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[2][1].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][1]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[2][3].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][3]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[2][7].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][7]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[2][4].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][4]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[2][8].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][8]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[2][5].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][5]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[2][9].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][9]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[2][6].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[2][6]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[3][0].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][0]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[3][2].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][2]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[3][1].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][1]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[3][3].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][3]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[3][7].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][7]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[3][4].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][4]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[3][8].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][8]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[3][5].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][5]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[3][9].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][9]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[3][6].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[3][6]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[4][0].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][0]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[4][2].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][2]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[4][1].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][1]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[4][3].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][3]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[4][7].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][7]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[4][4].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][4]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[4][8].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][8]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[4][5].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][5]}/>
                </div>
              </>
              <>
                <div className='single-card positioner'>
                  <img src={COURSES_LIBRARY[4][9].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][9]}/>
                </div>
                <div className='bottom-card positioner'>
                  <img src={COURSES_LIBRARY[4][6].img} alt="" />
                  <LibraryItemMetaData page={COURSES_LIBRARY[4][6]}/>
                </div>
              </>
            </Carousel>
          </div>
        )}
        <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
      </div>
      <div className='target-audience'>
        <h3>Who is SEEDJ for?</h3>
        <p>No matter if you are a beginner, intermediate or advanced player in the industry, our platform and service suits all the levels.</p>
        <div className='target-audience-tiles-wrapper'>
          <div className='target-audience-tile'>
            <h5 className='target-audience-tile-title'>Aspiring</h5>
            <p className='target-audience-tile-text'>It’s for you if you are an aspirant that has not started yet.</p>
          </div>
          <div className='target-audience-tile'>
            <h5 className='target-audience-tile-title'>Emerging</h5>
            <p className='target-audience-tile-text'>It’s for you if you have previous experience and want to level up your skills.</p>
          </div>
          <div className='target-audience-tile'>
            <h5 className='target-audience-tile-title'>Artists</h5>
            <p className='target-audience-tile-text'>It’s for you if you are already an artist that wants to keep growing and make valuable contacts.</p>
          </div>
        </div>
      </div>
      <div className="plan_selection_container">
        <div className='top-wrapper'>
          <div className='card-wrapper'>
            <img src={attachMoney} alt='' />
            <div>
              <h3>Your price will be flat forever</h3>
              <p>Get onboard and enjoy new benefits at the same price!</p>
            </div>
          </div>
          <div className='card-wrapper'>
            <img src={electricBoltTwo} alt='' />
            <div>
              <h3>Premium features</h3>
              <p>Unlock our premium features today for 35% OFF.</p>
            </div>
          </div>
        </div>
            <div className="plan_selection_elements_container">
                {paymentPlans?.data.map(plan => !plan.is_hidden && (
                    <div
                        className={`${activePlan === Math.round(plan.value) ? 'plan_selection_element plan_selection_element_active' : 'plan_selection_element'}`}
                        onClick={onSetActivePlan(Math.round(plan.value))}>
                        <div className="plan_selection_element_header">
                            <p>{plan.interval_count} months bundle {plan.discount && `- ${Math.round(plan.discount)}% OFF` } </p>
                            <div className="offer_box_container">
                                <div className="offer_box">Black Friday Offer</div>
                                {deviceType === 'mobile' &&
                                    <div className={'unselected_dot'}>
                                        {activePlan === Math.round(plan.value) && <div className="selected_dot"></div>}
                                    </div>}
                            </div>
                        </div>
                        <h2 className="price">€ {Math.round(plan.value)}</h2>
                        <h3 className="small_text">One-time-payment</h3>
                        <h3 className="small_text">(€ {Math.round((plan.value / plan.interval_count) * 10)/10} instead of 29€ per month)</h3>
                        <h3 className="small_text">This plan is a special promotion for Black Friday. There will be no
                            similar price here until next year.</h3>

                        <div className="align_flex">
                            <h2 className="benefit_title">Key benefits:</h2>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Professional Profile Creation</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Unlimited access to 150+ hours of Courses (+3 courses every month)</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Samples Library</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Daily Charts</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Direct chat with Record Labels and Managements</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Free Tutoring Sessions</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Private Community on Telegram</p>
                            </div>
                            <div className="benefit_row">
                                <img src={checked} alt=""/>
                                <p>Upload unlimited tracks and media</p>
                            </div>
                            <div className="benefit_row">
                                <img src={plan.interval_count < 6 ? unchecked : checked} alt=""/>
                                <p>Seedj 2.0 (Out soon)</p>
                            </div>
                            <div className="benefit_row">
                                <img src={plan.interval_count < 6 ? unchecked : checked} alt=""/>
                                <p>Contests & Demo Submission (Out soon)</p>
                            </div>
                            <div className="benefit_row">
                                <img src={plan.interval_count < 6 ? unchecked : checked} alt=""/>
                                <p>Plugin & Softwares (Out soon)</p>
                            </div>
                        </div>

                        <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>

                    </div>

                ))}
            </div>


            <div className="good_news">
                <div className="header_container">
                    <img src={tempestIcon} alt=""/>
                    <h2>Good news for 6 month memebrships!</h2>
                </div>
                <p>With the 6 months option you can access our courses registered in Early 2023 with
                    artists such as: Anetha, Indira Paganotto, Ilario Alicante, Lilly Palmer, Nastia, Nico Moreno, Øtta,
                    Alex Wilcox, Marc Declos, ABSL, Lacchesi, Caiva, Schacke, Chami, Psyk, Julian Muller
                    and more... at the lowest price.</p>
            </div>
      </div>
      <div className='mission'>
        <div className='mission-wrapper'>
          <div className='left-wrapper'>
            <h3>Our mission is to make the music industry a healthy and accessible place.</h3>
            <p>We contribute to the industry by helping those who want to undertake a path as an artist, no matter what.</p>
          </div>
          <div className='mission-tiles-wrapper'>
            <div className='mission-tile'>
              <h5 className='mission-tile-title'>3000+</h5>
              <p className='mission-tile-text'>Users worldwide</p>
            </div>
            <div className='mission-tile'>
              <h5 className='mission-tile-title'>60+</h5>
              <p className='mission-tile-text'>Countries</p>
            </div>
            <div className='mission-tile'>
              <h5 className='mission-tile-title'>+400</h5>
              <p className='mission-tile-text'>Success cases</p>
            </div>
          </div>
        </div>
      </div>
      <div className='navigate'>
        <img className='brand-identity-one' src={elementFive} alt=''/>
        {width > 768 && <img className='brand-identity-two' src={elementSix} alt=''/> }
        <div className='white-arrows mac'>
          <Carousel
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            showIndicators={true}
            swipeable
            autoPlay
            interval={10000}
            infiniteLoop
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={30}
          >
            <img src={chatSeedj}  alt='laptop'/>
            <img src={chartsSeedj}  alt='laptop'/>
            <img src={samplesSeedj}  alt='laptop'/>
          </Carousel>
        </div>
        <div className='right-wrapper'>
          <h3>Navigate our features.</h3>
          <p>We add new courses, partners, samples and charts month by month and you will be always updated about the upcoming contents.</p>
          <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
        </div>
      </div>
      <div className='partners'>
        <h3>We partner with</h3>
        <div className='partners-tiles-wrapper'>
          <div className='partners-tile'>
            <img src={musicNote} alt="note" />
            <h5 className='partners-tile-title'>+140 Labels</h5>
            <h6 className='partners-tile-subtitle'>Sign and release your tracks with record labels.</h6>
            <p className='partners-tile-text'>Every month, over 10 new labels join our platform to get in contact with our promoted artists.</p>
          </div>
          <div className='partners-tile'>
            <img src={discoverTuner} alt="discover" />
            <h5 className='partners-tile-title'>+35 Agencies</h5>
            <h6 className='partners-tile-subtitle'>Get gigs and go popular with our partner agencies. </h6>
            <p className='partners-tile-text'>Agencies periodically scout and send requests on Seedj to join their roster.</p>
          </div>
        </div>
        <div className='carousel-wrapper'>
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              showIndicators={true}
              swipeable
              autoPlay
              interval={10000}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
            >
              <div className='logo-carousel-wrapper'>
                <div className='logo-carousel-item-wrapper'>
                  <img src={daze} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={dj} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={djtechtools} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={elite} alt='logo' />
                </div>
              </div>
              <div className='logo-carousel-wrapper'>
                <div className='logo-carousel-item-wrapper'>
                  <img src={elite} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={eturia} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={faze} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={groove} alt='logo' />
                </div>
              </div>
              <div className='logo-carousel-wrapper'>
                <div className='logo-carousel-item-wrapper'>
                  <img src={mixmag} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={musictech} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={parallel} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={perctrax} alt='logo' />
                </div>
              </div>
              <div className='logo-carousel-wrapper'>
                <div className='logo-carousel-item-wrapper'>
                  <img src={playful} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={reflex} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={sowhul} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={suara} alt='logo' />
                </div>
              </div>
              <div className='logo-carousel-wrapper'>
                <div className='logo-carousel-item-wrapper'>
                  <img src={daze} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={dj} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={xxlab} alt='logo' />
                </div>
                <div className='logo-carousel-item-wrapper'>
                  <img src={yam} alt='logo' />
                </div>
              </div>
            </Carousel>
          </div>
      </div>
      <div className='news'>
        <div className='left-wrapper'>
          <h3>Something big is happenig very soon.</h3>
          <h3 className='grey'>Seedj 2.0 is coming in early 2023.</h3>
          <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
        </div>
        <div className='right-wrapper'>
          {width > 480 ? (
            <>
              <div>
                <h4>Personalized experience<span className='label'>NEW</span></h4>
                <p>We will introduce personalized "Paths" with an interactive onboarding to choose your start point and a personalized dashboard to track your achievements in the industry.</p>
              </div>
              <div>
                <h4>Contests<span className='label'>NEW</span></h4>
                <p>Competitions partnering with venues, record labels and medias giving you the opportunity to be noticed by other artists and realities in the music industry.</p>
              </div>
              <div>
                <h4>Demo submission<span className='label'>NEW</span></h4>
                <p>Upload your unreleased tracks to get played by recognised artists in their gigs all over the world and take your productions out your of your room.</p>
              </div>
              <div>
                <h4>Talent pool<span className='label'>NEW</span></h4>
                <p>You will be introduced by our team to high-tier labels, venues, medias and booking agencies to make high valuable new connections in the industry.</p>
              </div>
              <div>
                <h4>Plugins & Softwares<span className='label'>NEW</span></h4>
                <p>We will provide many paid plugins & softwares that will add much more value to your subscription. </p>
              </div>
              <div>
                <h4>And more</h4>
                <p>We will provide much more content (courses, masterclasses, samples and charts), new features (timer for learning time, profile insights, email notifications for selected courses, etc) and a complete new look.</p>
              </div>
            </>
          ) : (
            <>
              <Accordion allowMultipleExpanded allowZeroExpanded>
                {NEWS_STATE.map((item, index) => (
                    <AccordionItem key={index} className='faq-item'>
                        <AccordionItemHeading className='faq-heading'>
                          <AccordionItemButton>
                            {item.title}
                            <AccordionItemState>
                              {({ expanded }) => (expanded ? <img src={minusIcon} alt="minus" /> : <img src={crossIcon} alt="minus" />)}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className='faq-panel'>
                          {item.content}
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
            </>
          )}
        </div>
      </div>
      <div className='overview'>
        <img className='brand-identity-one' src={elementFive} alt=''/>
        {width > 768 && <img className='brand-identity-two' src={elementSix} alt=''/> }
        <h3>Get a quick overview of current and upcoming improvements.</h3>
        <p>We constantly update our platform with new integrations and features based on your feedbacks.</p>
        <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
        <img className='mac' src={overview}  alt='laptop'/>
        <p className='breadcrumbs'><span className={overview === oldSeedj && 'active'} onClick={() => setOverview(oldSeedj)}>Current</span>><span className={overview === currentSeedj && 'active'} onClick={() => setOverview(currentSeedj)}>December 2022</span>><span className={overview === newSeedj && 'active'} onClick={() => setOverview(newSeedj)}>March 2023</span></p>
      </div>
      <div className='feedback'>
        <h3>What DJs and Producers from all over the world say about us</h3>
        {width >= 768 ? (
          <div className='feedback-tiles-wrapper'>
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              showIndicators={true}
              swipeable
              autoPlay
              interval={10000}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
            >
              <div className='display-type'>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Meno Lopes</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Love it!</p>
                  <p className='feedback-tile-text'>I subscribed to seedj as one of the first 500 people for a discounted price & since it's launch i'm so glad i subscribed! So many amazing artists to learn from & they keep adding more artists, honestly i can't keep up with all the courses but i love the fact that i have access to so many courses. I loved the courses i've finished so far & i can't wait to apply what i've learnt. One thing i absolutely love about seedj is that they're super transparent about everything & they've improved so much overtime. If anyone is reading this & wondering whether to subscribe or not, close your eyes and DO IT! :D</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Darren Winter</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Love SeedDJ - I’ve watched some real high class tutorials and workshops around music production and DJing from European artists and they’ve really helped me develop my sound, confidence and quality of work.</p>
                  <p className='feedback-tile-text'>The platform gets new content every month and they work hard to improve the user experience.</p>
                  <p className='feedback-tile-text'>I like the idea of uploading music to get in front of labels, just started doing this so it will be interesting to see what happens.</p>
                  <p className='feedback-tile-text'>Recommended</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>The Magic Noise</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>I have been a member of Seedj for less than a month and I am having a great time. I find the idea brilliant and one of a kind. I enjoy watching the tutorials that have allowed me to receive answers to personal questions that I was struggling to find solutions to. And actually for all emerging and non-emerging producers, it is much easier to have direct contact with the labels and publish their works. I recommend it to both producers and labels.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
              </div>
              <div className='display-type'>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Chemical Dumpster</h5>
                  <img src={trustpilotFourStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Hammer idea, I was one of the first to sign up for Seedj, as soon as I saw endorsements from some major names in the industry. I love how they offer a platform that changes the dynamics between labels and artists. I still believe that is more relevant than samples or online courses. The samples are good and the courses are excellent but there are other platforms that can compete with that. But the platform where labels can reach out to artists directly is something unique. In terms of software it is a work in progress and month over month it gets better and easier to use. The one big mistake and the reason this was not a 5 is that they removed their mobile version before having a new version to replace it.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Paolo Peruzzi</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Can't express how much I'm learning with SEEDJ's lessons from lots of big artists.Very big concept with a huge amount of possibilities.Also I've made few contacts with labels, but not with agencies for djs unfortunately.I really suggest this platform, I'm inside since the beginning (did the pre-order and never quit) and I think that with time will continue growing stronger.Good Work and continue 💪💣</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Lorenzo Bondanini</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>I have been a member of Seedj for less than a month and I am having a great time. I find the idea brilliant and one of a kind. I enjoy watching the tutorials that have allowed me to receive answers to personal questions that I was struggling to find solutions to. And actually for all emerging and non-emerging producers, it is much easier to have direct contact with the labels and publish their works. I recommend it to both producers and labels.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
              </div>
              <div className='display-type'>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Haüt Mxl</h5>
                  <img src={trustpilotFourStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>This platform really helped me With my djing and producing skills. I recommend it to every producer who want to take a step ahead in their music.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Jacob Nielsen</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Really like this Netflix-ish platform for developing my produktion and DJ skills. Some produktions is better than other, but i'd watch these things over Netflix any day :-) Game on</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Ernest Bartosevic</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Great content. Literally coming back to SeeDJ every day!</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
              </div>
              <div className='display-type'>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Ci Clara</h5>
                  <img src={trustpilotFourStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>A lot happened. I got a lot of requests about the tracks I uploaded and my first EP will come in October. Also a management asking me for collaboration, I am just happy. I learned a lot from Dj and producer courses, thanks so much for this great platform.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>the spino</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>The future of electronic music production education. Great platform where to learn how to produce AND push your arts out there in the wild. Game changer.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
                <div className='feedback-tile'>
                  <h5 className='feedback-tile-name'>Bassywax</h5>
                  <img src={trustpilotStars} alt='trustpilot-stars'/>
                  <p className='feedback-tile-text'>Awesome platform idea. Great content, learning a lot. User experience properly improving and user service on point.</p>
                  <div className='bottom-spacer'/>
                  <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
                </div>
              </div>
            </Carousel>
          </div>
        ) : (
          <div className='feedback-tiles-wrapper'>
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              showIndicators={true}
              swipeable
              autoPlay
              interval={10000}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
            >
              <div className='feedback-tile carousel-tile'>
                <h5 className='feedback-tile-name'>Meno Lopes</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Love it!</p>
                <p className='feedback-tile-text'>I subscribed to seedj as one of the first 500 people for a discounted price & since it's launch i'm so glad i subscribed! So many amazing artists to learn from & they keep adding more artists, honestly i can't keep up with all the courses but i love the fact that i have access to so many courses. I loved the courses i've finished so far & i can't wait to apply what i've learnt. One thing i absolutely love about seedj is that they're super transparent about everything & they've improved so much overtime. If anyone is reading this & wondering whether to subscribe or not, close your eyes and DO IT! :D</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile carousel-tile'>
                <h5 className='feedback-tile-name'>Darren Winter</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Love SeedDJ - I’ve watched some real high class tutorials and workshops around music production and DJing from European artists and they’ve really helped me develop my sound, confidence and quality of work.</p>
                <p className='feedback-tile-text'>The platform gets new content every month and they work hard to improve the user experience.</p>
                <p className='feedback-tile-text'>I like the idea of uploading music to get in front of labels, just started doing this so it will be interesting to see what happens.</p>
                <p className='feedback-tile-text'>Recommended</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile carousel-tile'>
                <h5 className='feedback-tile-name'>The Magic Noise</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>I have been a member of Seedj for less than a month and I am having a great time. I find the idea brilliant and one of a kind. I enjoy watching the tutorials that have allowed me to receive answers to personal questions that I was struggling to find solutions to. And actually for all emerging and non-emerging producers, it is much easier to have direct contact with the labels and publish their works. I recommend it to both producers and labels.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Chemical Dumpster</h5>
                <img src={trustpilotFourStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Hammer idea, I was one of the first to sign up for Seedj, as soon as I saw endorsements from some major names in the industry. I love how they offer a platform that changes the dynamics between labels and artists. I still believe that is more relevant than samples or online courses. The samples are good and the courses are excellent but there are other platforms that can compete with that. But the platform where labels can reach out to artists directly is something unique. In terms of software it is a work in progress and month over month it gets better and easier to use. The one big mistake and the reason this was not a 5 is that they removed their mobile version before having a new version to replace it.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Paolo Peruzzi</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Can't express how much I'm learning with SEEDJ's lessons from lots of big artists.Very big concept with a huge amount of possibilities.Also I've made few contacts with labels, but not with agencies for djs unfortunately.I really suggest this platform, I'm inside since the beginning (did the pre-order and never quit) and I think that with time will continue growing stronger.Good Work and continue 💪💣</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Lorenzo Bondanini</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>I have been a member of Seedj for less than a month and I am having a great time. I find the idea brilliant and one of a kind. I enjoy watching the tutorials that have allowed me to receive answers to personal questions that I was struggling to find solutions to. And actually for all emerging and non-emerging producers, it is much easier to have direct contact with the labels and publish their works. I recommend it to both producers and labels.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Haüt Mxl</h5>
                <img src={trustpilotFourStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>This platform really helped me With my djing and producing skills. I recommend it to every producer who want to take a step ahead in their music.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Jacob Nielsen</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Really like this Netflix-ish platform for developing my produktion and DJ skills. Some produktions is better than other, but i'd watch these things over Netflix any day :-) Game on</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Ernest Bartosevic</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Great content. Literally coming back to SeeDJ every day!</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Ci Clara</h5>
                <img src={trustpilotFourStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>A lot happened. I got a lot of requests about the tracks I uploaded and my first EP will come in October. Also a management asking me for collaboration, I am just happy. I learned a lot from Dj and producer courses , thanks so much for this great platform.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>the spino</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>The future of electronic music production education. Great platform where to learn how to produce AND push your arts out there in the wild. Game changer.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
              <div className='feedback-tile'>
                <h5 className='feedback-tile-name'>Bassywax</h5>
                <img src={trustpilotStars} alt='trustpilot-stars'/>
                <p className='feedback-tile-text'>Awesome platform idea. Great content, learning a lot. User experience properly improving and user service on point.</p>
                <div className='bottom-spacer'/>
                <p className='feedback-tile-caption'>Powered by <span>Trustpilot</span></p>
              </div>
            </Carousel>
          </div>
        )}
      </div>
      <div className='faq'>
        <h3>FAQ</h3>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {FAQ_STATE.map((item, index) => (
              <AccordionItem key={index} className='faq-item'>
                  <AccordionItemHeading className='faq-heading'>
                    <AccordionItemButton>
                      {item.title}
                      <AccordionItemState>
                        {({ expanded }) => (expanded ? <img src={minusIcon} alt="minus" /> : <img src={crossIcon} alt="minus" />)}
                      </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='faq-panel'>
                    {item.content}
                  </AccordionItemPanel>
              </AccordionItem>
          ))}
      </Accordion>
      </div>
      <div className='bottom-cta'>
        {<h2 className='cta--grey'>Few seats are available.</h2>}
        <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
      </div>
      <div className='footer'>
        <div className='top-section'>
          <div className='left'>
            <img src={seedjLogo} alt='seedj logo' />
            <p>The first digital accelerator for DJs and Producers in electronic music industry.</p>
            <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
          </div>
          <div className='spacer'/>
          <div className='right'>
            <a href='https://www.iubenda.com/privacy-policy/18356441' className='footer-link'>Privacy Policy</a>
            <a href='https://www.iubenda.com/privacy-policy/18356441/cookie-policy' className='footer-link'>Cookies Policy</a>
            <a href='https://www.iubenda.com/terms-and-conditions/18356441' className='footer-link'>Terms and Conditions</a>
          </div>
        </div>
        <p className='footer-text'>Made with ♥ All rights are reserved © 2022 - Seedj</p>
      </div>
      <div className='cta-banner'>
        <h3 className='title'>Get up to <span>44% off</span> in your subscription.</h3>
        <div></div>
        <div className='count-wrapper'>
        <h3 className='countdown'>
          <div>
            <div className='countdown-number'>{countdown.days}</div>
            <div className='timer-text'>Days</div>
          </div>
          <div>:</div>
          <div>
            <div className='countdown-number'>{countdown.hours}</div>
            <div className='timer-text'>Hours</div>
          </div>
          <div>:</div>
          <div>
            <div className='countdown-number'>{countdown.minutes}</div>
            <div className='timer-text'>Minutes</div>
          </div>
          <div>:</div>
          <div>
            <div className='countdown-number'>{countdown.seconds}</div>
            <div className='timer-text'>Seconds</div>
          </div>
        </h3>
        </div>
        <div className='button' onClick={() => navigate('/checkout2.0')}><p>Get the offer</p></div>
      </div>
    </div>
  )
}

export default LandingPage;

const LibraryItemMetaData = ({ page }) => {
  return (
    <div className='tutor-info'>
      {page.status === 'coming soon' ? <span className='upcoming-badge'>UPCOMING</span> : <span className='available-badge'>AVAILABLE</span>}
      {page.status === 'coming soon'
        ? <h6 className='extra-info'>{page.category} · {page.duration}</h6>
        : <h6 className='extra-info'>{page.duration} · {page.chapters} chapters · {page.views} views</h6>
      }
      <div className='spacer'/>
      <h4 className='tutor-name'>{page.tutorName}</h4>
      <h5 className='tutor-category'>{page.category}</h5>
    </div>
  )
}

const COURSES_LIBRARY = [
  [
    {
      tutorName: 'SPFDJ',
      duration: '2 hrs',
      chapters: 9,
      category: 'DJing',
      views: 9142,
      status: 'available',
      img: spfdj
    },
    {
      tutorName: 'Anetha',
      duration: '1.5 hrs',
      category: 'DJing',
      status: 'coming soon',
      img: anetha
    },
    {
      tutorName: 'Perc',
      duration: '2 hrs',
      chapters: 13,
      category: 'Music Business & Production',
      views: 3238,
      status: 'available',
      img: perc
    },
    {
      tutorName: 'Vil & Cravo',
      duration: '2.5 hrs',
      chapters: 5,
      category: 'Producing',
      views: 1170,
      status: 'available',
      img: vilAndCravo
    },
    {
      tutorName: 'TRYM',
      duration: '3 hrs',
      chapters: 4,
      category: 'DJing',
      views: 2348,
      status: 'available',
      img: trym
    },
    {
      tutorName: 'Ilario Alicante',
      duration: '1.5 hrs',
      category: 'DJing',
      status: 'coming soon',
      img: ilarioAlicante
    },
    {
      tutorName: 'Nastia',
      duration: '1.5 hrs',
      category: 'DJing',
      status: 'coming soon',
      img: nastia
    },
    {
      tutorName: 'Alarico',
      duration: '4 hrs',
      chapters: 4,
      category: 'Production',
      views: 4759,
      status: 'available',
      img: alarico
    },
    {
      tutorName: 'Chlär',
      duration: '2.5 hrs',
      chapters: 10,
      category: 'Production',
      views: 4491,
      status: 'available',
      img: chlaer
    },
    {
      tutorName: 'Shlømo',
      duration: '1 hrs',
      chapters: 4,
      category: 'DJing',
      views: 3137,
      status: 'available',
      img: shlomo
    }
  ],
  [
    {
      tutorName: 'Adiel',
      duration: '1 hr',
      chapters: 4,
      category: 'DJing',
      views: 1294,
      status: 'available',
      img: adiel
    },
    {
      tutorName: 'François X',
      duration: '1.5 hrs',
      chapters: 4,
      category: 'Production',
      views: 1081,
      status: 'available',
      img: francoisX
    },
    {
      tutorName: 'Z.I.P.P.O',
      duration: '1.5 hrs',
      chapters: 6,
      category: 'Djing & Production',
      views: 3491,
      status: 'available',
      img: zippo
    },
    {
      tutorName: 'Anastasia Kristensen',
      duration: '1 hr',
      chapters: 7,
      category: 'DJing',
      views: 1134,
      status: 'available',
      img: anastasiaKristensen
    },
    {
      tutorName: 'Nico Moreno',
      duration: '1.5 hrs',
      category: 'DJing',
      views: 1134,
      status: 'coming soon',
      img: nicoMoreno
    },
    {
      tutorName: 'Indira Paganotto',
      duration: '1.5 hrs',
      category: 'DJing & Production',
      status: 'coming soon',
      img: alignment
    },
    {
      tutorName: 'Scalameriya',
      duration: '1.5 hrs',
      category: 'Production',
      status: 'coming soon',
      img: scalameriya
    },
    {
      tutorName: 'Hadone',
      duration: '1.5 hrs',
      chapters: 3,
      category: 'Production',
      views: 1217,
      status: 'available',
      img: hadone
    },
    {
      tutorName: 'Quelza',
      duration: '10 hrs',
      chapters: 8,
      category: 'Production',
      views: 2174,
      status: 'available',
      img: quelza
    },
    {
      tutorName: 'Manni Dee',
      duration: '1.5 hrs',
      chapters: 4,
      category: 'DJing & Production',
      views: 2053,
      status: 'available',
      img: manniDee
    }
  ],
  [
    {
      tutorName: 'Ayarcana',
      duration: '2 hrs',
      chapters: 3,
      category: 'Production',
      views: 920,
      status: 'available',
      img: ayarcana
    },
    {
      tutorName: 'Gaetano Parisio',
      duration: '2 hrs',
      chapters: 7,
      category: 'DJing',
      views: 1594,
      status: 'available',
      img: geatanoParisio
    },
    {
      tutorName: 'Reemco Beekwilder',
      duration: '2.5 hrs',
      chapters: 5,
      category: 'Production',
      views: 1021,
      status: 'available',
      img: reemco
    },
    {
      tutorName: 'Volruptus',
      duration: '2.5 hrs',
      chapters: 10,
      category: 'Production',
      views: 1443,
      status: 'available',
      img: volruptus
    },
    {
      tutorName: 'Lilly Palmer',
      duration: '1.5 hrs',
      category: 'Production',
      status: 'coming soon',
      img: lillyPalmer
    },
    {
      tutorName: 'Øtta',
      duration: '1.5 hrs',
      category: 'DJing',
      status: 'coming soon',
      img: otta
    },
    {
      tutorName: 'Lucy',
      duration: '2 hrs',
      chapters: 6,
      category: 'Production',
      views: 711,
      status: 'available',
      img: and
    },
    {
      tutorName: 'UVB',
      duration: '1 hr',
      chapters: 4,
      category: 'DJing',
      views: 1399,
      status: 'available',
      img: uvb
    },
    {
      tutorName: 'Luca Agnelli',
      duration: '1 hr',
      chapters: 4,
      category: 'Music Business',
      views: 553,
      status: 'available',
      img: lucaAgnelli
    },
    {
      tutorName: 'Cynthia Spiering',
      duration: '2 hrs',
      chapters: 4,
      category: 'Production',
      views: 849,
      status: 'available',
      img: cynthiaSpiering
    },
  ],
  [
    {
      tutorName: 'Artik',
      duration: '5 hrs',
      chapters: 6,
      category: 'Production',
      views: 887,
      status: 'available',
      img: artik
    },
    {
      tutorName: 'Julian Muller',
      duration: '1.55 hrs',
      category: 'Production',
      status: 'coming soon',
      img: julianMuller
    },
    {
      tutorName: 'Joe Farr',
      duration: '2.5 hrs',
      chapters: 11,
      category: 'Production',
      views: 1565,
      status: 'available',
      img: joeFarr
    },
    {
      tutorName: 'Arthur Robert',
      duration: '2 hrs',
      chapters: 3,
      category: 'Production',
      views: 762,
      status: 'available',
      img: arthurRobert
    },
    {
      tutorName: 'Casual Treatment',
      duration: '10 hrs',
      chapters: 6,
      category: 'Production',
      views: 803,
      status: 'available',
      img: casualTreatment
    },
    {
      tutorName: 'Caiva',
      duration: '1.5 hrs',
      category: 'Production',
      status: 'coming soon',
      img: caiva
    },
    {
      tutorName: 'Schacke',
      duration: '1.5 hrs',
      category: 'Production',
      status: 'coming soon',
      img: schacke
    },
    {
      tutorName: 'Tred',
      duration: '2 hrs',
      chapters: 4,
      category: 'Production',
      views: 1493,
      status: 'available',
      img: tred
    },
    {
      tutorName: 'Raffaele Attanasio',
      duration: '1.5 hrs',
      chapters: 6,
      category: 'Live Show',
      views: 1094,
      status: 'available',
      img: raffaeleAttanasio
    },
    {
      tutorName: 'Blicz',
      duration: '2.5 hrs',
      chapters: 4,
      category: 'Production',
      views: 1154,
      status: 'available',
      img: blicz
    },
  ],
  [
    {
      tutorName: 'BEC',
      duration: '2 hrs',
      chapters: 9,
      category: 'Production & Music Business',
      views: 1914,
      status: 'available',
      img: bec
    },
    {
      tutorName: 'Katatonic Silentio',
      duration: '2 hrs',
      chapters: 17,
      category: 'Sound Design',
      views: 1449,
      status: 'available',
      img: katatonicSilentio
    },
    {
      tutorName: 'DJ Plant Texture',
      duration: '1 hrs',
      chapters: 1,
      category: 'Production',
      views: 210,
      status: 'available',
      img: djPlantTexture
    },
    {
      tutorName: 'Claudio PRC',
      duration: '2 hrs',
      chapters: 5,
      category: 'Production',
      views: 1563,
      status: 'available',
      img: claudioPRC
    },
    {
      tutorName: 'Mac Declos',
      duration: '1.5 hrs',
      category: 'DJing and Production',
      status: 'coming soon',
      img: macDeclos
    },
    {
      tutorName: 'Psyk',
      duration: '1.5 hrs',
      category: 'DJing',
      status: 'coming soon',
      img: psyk
    },
    {
      tutorName: 'Mattia Trani',
      duration: '1.5 hrs',
      category: 'Live Show',
      status: 'coming soon',
      img: mattiaTrani
    },
    {
      tutorName: 'No.name',
      duration: '1 hr',
      chapters: 2,
      category: 'Production',
      views: 517,
      status: 'available',
      img: noName
    },
    {
      tutorName: 'Coyu',
      duration: '1 hr',
      chapters: 9,
      category: 'Music Business',
      views: 935,
      status: 'available',
      img: coyu
    },
    {
      tutorName: 'Basswell',
      duration: '1 hr',
      chapters: 6,
      category: 'Production',
      views: 649,
      status: 'available',
      img: basswell
    },
  ]
]
