import React, { useEffect } from "react";
import "./App.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Routes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

//import {analytics as firebase} from "./firebase";
const queryClient = new QueryClient();

const initialOptions = {
  //"client-id": "AcxBcM-8S_SYOmB06Y-wtZ4_IHkZW3Tq_oam2X5U0gzYtybdBXMet0FlvxNq9ioL0d-GwIyso183K68e",
  "client-id":
    "AbK-dFXEv7JnMywFhA8tzyCIvTE_O1gc_IUiwac5-D2TZb7CXim5CJflDTO0ARchq1vlYDPIGPolzkMI",
  currency: "EUR",
  intent: "subscription",
  vault: true,
  components: "buttons",
};

function App() {
  /*useEffect(()=>{
      firebase.analytics()
    },[])*/

  return (
    <React.Fragment>
      <ToastContainer limit={1} />
      <QueryClientProvider client={queryClient}>
        <Routes />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
