import React, {useCallback, useEffect, useState} from 'react'
import translate from "../../utils/locale/language";
import "./posttrackep.scss";
import Dropzone, {useDropzone} from "react-dropzone";
import {
    getMethod,
    getMethodOpen,
    postMethodAuthenticated,
    postMethodAuthenticatedProgress
} from "../../utils/apis/services/api";
import urls from "../../utils/apis/services/apiurls.json";
import Lens from '../../assets/images/posttrack/photolens.svg'
import {toast} from "react-toastify";
import {Line} from "rc-progress";
import Select from "react-dropdown-select";
import AddButton from "../addButton/addButton";
import {useNavigate} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';


const initialTrackState = {
    name: "",
    trackId: {
        track: "",
        title: ""
    },
    genre: "",
    subgenres: [],
    labels: [],
    description: "",
    cover_image: ""
}


export default () => {
    const [step, setStep] = useState("uploadTrack")
    const [trackInfo, setTrackInfo] = useState(initialTrackState)
    const [uploadPercent, setUploadPercent] = useState(0)
    const [isUploading, setIsUploading] = useState(false)
    const { width } = useWindowDimensions()

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total)
        setUploadPercent(percent)
    }

    const handleFiles = async (e, type) => {
        let formData = new FormData();
        if (type === "previewimage") {
            formData.append("file", e);
            try {
                const {data: {id}, status} = await postMethodAuthenticated(urls.fileupload, formData);
                if (status > 200) throw new Error()
                setTrackInfo(trackInfo => ({...trackInfo, cover_image: id}))
            } catch (e) {
                toast.error("Sorry, something went wrong")
            }

        } else if (type === "track") {
            formData.append("file", e);
            setIsUploading(true)
            try {
                const {
                    data: {id},
                    status
                } = await postMethodAuthenticatedProgress(urls.fileupload, formData, onUploadProgress);
                if (status > 200) throw new Error()
                setTrackInfo(trackInfo => ({...trackInfo, trackId: {...trackInfo.trackId, track: id}}))
                setStep("insertInfo")
            } catch (e) {
                toast.error("Sorry, something went wrong")
                setIsUploading(false)
            }

        }
    };

    const onDropTrack = useCallback(acceptedFiles => {
        acceptedFiles.map(file => {
            setTrackInfo(trackInfo => ({...trackInfo, trackId: {...trackInfo.trackId, title: file.name}}))
            handleFiles(file, "track")
        })
    }, []);

    const postAtrackHandler = async () => {
        if (trackInfo.name.length !== "" && trackInfo.genre !== "" && trackInfo.description !== "" && trackInfo.subgenres.length !== 0) {

            let body = {
                description: trackInfo.description,
                genre: trackInfo.genre[0],
                subgenre: trackInfo.subgenres.map((item) => {
                    return item.slug
                }),
                cover_image: trackInfo.cover_image,
                track: trackInfo.trackId.track,
                title: [trackInfo.name],
                label: trackInfo.labels.map(label => label.id)

            };
            try {
                let result = await postMethodAuthenticated(urls.posttrackep, body);
                if (!result.data.Error && result.status === 200) {
                    setStep("trackUploaded")
                } else throw new Error(result.data.Error)
            } catch (e) {
                toast.error(e)
            }

        } else {
            toast.error('Please fill all the required inputs (Title, Genre, SubGenre, Description)')
        }
    };

    const renderContent = () => {
        switch (step) {
            case "uploadTrack" :
                return <TrackUploader onDropTrack={onDropTrack}/>

            case "insertInfo" :
                return <InfoContainer postImage={handleFiles} postTrack={postAtrackHandler}
                                      uploadPercentage={uploadPercent} trackInfo={trackInfo}
                                      setTrackInfo={setTrackInfo} stopLoader={() => setIsUploading(false)}/>

            case "trackUploaded" :
                return <TrackUploaded setTab={setStep}/>
        }
    }

    if (width <= 480) {
        return (
            <div className="postTrackEp">
                <h2 className="mainheading">
                You can upload your tracks to be reached by record labels only from your desktop
                </h2>
            </div>
        )
    }

    return (
        <div className="postTrackEp">
            <h2 className="mainheading">
                {translate("postatrack")}
            </h2>
            <LoadingOverlay
                active={isUploading}
                spinner
                text="Uploading...">
                {renderContent()}
            </LoadingOverlay>
        </div>
    )
}


const TrackUploader = ({onDropTrack}) => {
    return (
        <div className="selecteduploadbox">
            <Dropzone onDrop={onDropTrack} accept="audio/*">
                {({getRootProps, getInputProps}) => (
                    <div className="postatrack_uploadtrackorep" {...getRootProps()}>
                        <label className="custom-file-upload">
                            <input
                                {...getInputProps()}
                            />
                            <h1>{translate("uploadaudio")}</h1>
                        </label>
                        <button>{translate("uploadfile")}</button>
                    </div>
                )}
            </Dropzone>
        </div>
    )
}

const InfoContainer = ({uploadPercent = 100, trackInfo, setTrackInfo, postTrack, postImage, stopLoader}) => {
    const [genrelist, setGenreOptions] = useState([])
    const [labeloptions, setLabeloptions] = useState([])
    const [previewImage, setPreviewImage] = useState()
    const [subgenres, setSubgenres] = useState([])

    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: [
            'image/jpeg',
            'image/png'
        ]
    });


    const getGenreList = async () => {
        let {data} = await getMethodOpen(urls.genrelist)
        setGenreOptions(data ? data : [])
    }

    const getLabelList = async () => {
        let {data} = await getMethod(urls.getlabellist, "")
        setLabeloptions(data ? data : [])
    }

    useEffect(() => {
        stopLoader()
        getGenreList()
        getLabelList()
    }, [])

    useEffect(() => {

        const imageHandler = async () => {
            await postImage(acceptedFiles[0], 'previewimage')
            setPreviewImage(acceptedFiles[0])
        }
        if (acceptedFiles.length > 0) {
            imageHandler()
            postImage(acceptedFiles[0], 'previewimage')
        }
    }, [acceptedFiles])


    return (
        <div className="posttrack_inser_info">
            <div className="posttrack_percentage_text">
                <span className="percentagevalue">{uploadPercent}%</span>
            </div>
            <div className="trackepprogress" style={{display: "flex"}}>
                <Line
                    className="stroke"
                    percent={uploadPercent}
                    strokeWidth="10"
                    trailWidth="10"
                    trailColor="transparent"
                    strokeColor="#33FECC"
                />
            </div>


            <div className="posttrack_info_container">
                <div className="posttrack_info_body">
                    <div className="posttrack_image_container">
                        {previewImage && <img className="posttrack_info_body_preview_image"
                                              src={URL.createObjectURL(previewImage)} alt=""/>}
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <button onClick={open}>
                                <img src={Lens} alt=""/>
                                <p>Add image</p>
                            </button>
                        </div>
                    </div>

                    <div className="posttrack_form_container">

                        <div className="tracktitle">
                            <p>{translate("title")}</p>
                            <input
                                type="text"
                                placeholder={translate("addtracktitle")}
                                name="title"
                                onChange={(e) => setTrackInfo(trackInfo => ({...trackInfo, name: e.target.value}))}
                                required
                            />
                        </div>


                        <div className="posttrack_select_row">
                            <p>{translate("genre") + ": "}</p>
                            <Select noDataLabel={translate("findgenre")} options={genrelist}
                                    labelField={"genre_name"} valueField={"slug"} onChange={(values) => {
                                setTrackInfo(trackInfo => ({
                                    ...trackInfo,
                                    genre: [...trackInfo.genre, values[0].slug]
                                }))
                                setSubgenres(values[0].subgenre)

                            }} className="ReactDropdownSelect"/>
                        </div>

                        <div className="posttrack_select_row">

                            <p>{translate("subgenre")}</p>
                            <Select noDataLabel={translate("findsubgenre")} multi options={subgenres}
                                    labelField={"genre_name"} valueField={"slug"} values={trackInfo.subgenre}
                                    onChange={(values) => setTrackInfo(trackinfo => ({
                                        ...trackinfo,
                                        subgenres: [...values]
                                    }))}
                                    className="ReactDropdownSelect"/>
                        </div>

                        <div className="posttrack_select_row">

                            <p>Labels</p>
                            <Select noDataLabel={"find your label"} multi options={labeloptions}
                                    labelField={"full_name"} valueField={"full_name"} values={trackInfo.labels}
                                    onChange={(values) => setTrackInfo(trackinfo => ({
                                        ...trackinfo,
                                        labels: [...values]
                                    }))}
                                    className="ReactDropdownSelect"/>
                        </div>

                        <div className="posttrack_description">
                            <p>{translate("commentontrack")}</p>
                            <textarea
                                type="text"
                                placeholder={translate("additionaldescription")}
                                name="description"
                                onChange={(e) => setTrackInfo(trackInfo => ({
                                    ...trackInfo,
                                    description: e.target.value
                                }))}
                                required
                            />
                        </div>


                    </div>
                </div>

                <div className="posttrack_info_container_footer">
                    <button onClick={postTrack}>{translate("uploadeptrack")}</button>
                </div>

            </div>
        </div>
    )
}

const TrackUploaded = ({setTab}) => {
    const navigate = useNavigate()

    return (
        <div className="posttrack_track_uploaded_container">
            <h1>{translate("yourtrackbeenuploaded")}</h1>
            <AddButton labelStyle={{color: "#33FECC"}} label={translate("uploadnextrack")}
                       onClick={() => setTab("uploadTrack")}/>
            <span>or</span>
            <p onClick={() => navigate('/user/home')}>{translate("getmetodashboard")}</p>

        </div>
    )
}

