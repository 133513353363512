import React, {useState} from 'react'
import Plus from "../../assets/images/navbar/plus.svg";
import PlusHover from "../../assets/images/navbar/plusHover.svg";
import "./addButton.scss"

const AddButton = ({onClick, label, style= {}, labelStyle= {}}) => {
    const [plusIcon, setPlusIcon] = useState(Plus)

    const hoverPlusIcon = () => setPlusIcon(PlusHover)
    const regularPlusIcon = () => setPlusIcon(Plus)

    return (
        <div
            style={{...style}}
            onClick={onClick}
            onMouseOver={hoverPlusIcon}
            onMouseLeave={regularPlusIcon}
            className="component_addButton"
        >
            <img width={'20px'} height={'20px'} className={"addMusic"} src={plusIcon}/>
            <span style={{...labelStyle}}>{label}</span>
        </div>
    )
}

export default AddButton;