const it = {
  home: "Home",
  library: "Corsi",
  exclusivesamples: "Samples",
  weeklycharts: "Le tue charts settimanali",
  signrequest: "Richieste",
  challanges: "Sfide",
  contents: "Contest",
  community: "Community",
  hello: "Ciao",
  welcome: "Benvenuto!",
  weolcomeBack: "Bentornato,",
  username: "Username",
  rememberme: "Ricordati di me",
  signin: "Accedi",
  forgotpassword: "Password dimenticata?",
  signinwith: "o accedi con",
  donthaveaccount: "Non hai un account?",
  signup: "Registrati",
  create: "Crea",
  account: "Account",
  addmusic: "Aggiungi musica",
  profile: "Profilo",
  setting: "Impostazioni",
  logout: "Esci",
  postatrack: "Carica la tua traccia ",
  track:"Traccia",
  postep: "Pubblica un EP",
  fresh: "Nuova",
  music: " musica",
  oftoday: " del giorno",
  your: "le tue",
  lessons: " lezioni",
  tocontinue: " da continuare",
  tofollow:" Da seguire ",
  hot: "Hot",
  samples: " Samples",
  exclusive: "esclusivi",
  download: "Download",
  monthly: "mensili",
  soon:" presto ",
  charts: " Charts",
  by: " da",
  newcontents: "Nuovi contenuti",
  foryou: "scelti per te ",
  gotocharts: "Vai alle charts",
  seedjcommunity: "SEEDJ community",
  librarysubheading: "Contenuti video su DJing, produzione musicale e business della musica.",
  masterclass: "Masterclasses",
  producingtutorials: "Tutorials sulla produzione",
  musictheory: "Teoria Musicale",
  backstage: "Backstage",
  info: "Info",
  mymediakit: "Il mio Media Kit",
  mytracksep: "Le mie tracce ",
  loginsuccess: "L'accesso è avvenuto con successo",
  loginerror: "Si prega di fornire le credenziali corrette ",
  signupsuccess: "Iscrizione avvenuta con successo",
  resetpassword: "Reimposta la password",
  title: "Titolo",
  addtracktitle: "Aggiungi il titolo della tua traccia qui... ",
  describetrack: "Descrivi la tua traccia per far capire agli ascoltatori di cosa si tratta... ",
  genre: "Genere",
  genreplaceholder: "Aggiungi parole chiave per far conoscere la tua traccia",
  previewimage: "Anteprima dell'immagine ",
  selectimagetrack: "Carica un artwork per la tua traccia ",
  preview: "Anteprima",
  postonprofile: "Pubblicala nel tuo profilo.",
  commentontrack: "Descrizione",
  additionaldescription: "Scrivi una descrizione aggiuntiva",
  writedescription: "Come è stata prodotta, quali sono le tue sensazioni a riguardo...",
  monitoring: "Monitoraggio",
  monitoringsubheading: "Aggiungi labels per rendere più visibile la tua traccia",
  monitoringplaceholder: "Tagga qui alcune labels con cui ti piacerebbe collaborare",
  lable: "Label",
  uploadeptrack: "Carica una traccia",
  uploadepep: "Carica un EP",
  uploadaudio: "Trascina la tua traccia qui.",
  "uploadfile": "oppure scegli un file da caricare",
  drangdrophere: "oppure trascina e rilascialo qui ",
  next: "Successivo",
  fileuploadsuccess: "File caricato con successo ",
  trackuploadsuccess: "Traccia caricata con successo ",
  epuploadsuccess: "EP caricato con successo",
  profilesigned:"Profile pubblicato",
  profilereviewed:"Profilo esaminato",
  similartoyours:"simili al tuo ",
  haveviewed:"già visti",
  agency:"Agenzia",
  tracksigned:"Tracce pubblicate",
  trackreviewed:"Tracce esaminate",
  havelistened:"già ascoltati",
  labels:"Labels",
  allcharts:"TUTTE LE CHARTS",
  newcharts:"Nuove charts",
  savedsamples:"Samples salvati",
  savedprofiles:"Profili salvati",
  savedtracks:"Tracce",
  saved:"Salvato ",
  addnewmedia:"Aggungi un nuovo contenuto ",
  photoorvideo:"Foto o video",
  addnewmusic:"Aggiungi nuova musica ",
  trackoreps:"Tracce",
  sendlink:"Invia link",
  ForgotPassword:"Password dimenticata",
  bestofmonth:"Il meglio del mese",
  exclusiveloopsamples:"Loop & Samples esclusivi",
  trendingnow:"I NUOVI TREND",
  mostviewed:"I PIÙ VISTI",
  startyourclimb:"Inizia la salita.",
  seedjisonline:"Fuori il 30 Agosto 2021",
  elctronicmusic:"Il meglio della musica elettronica a tua disposizione.",
  allinone:"Il tutto con un solo abbonamento. Puoi disiscriverti quando vuoi.",
  checkoutemailplaceholder:"Inserisci la tua email per preordinare",
  getexclusive:"Ottieni esclusivi",
  contacts:"contatti",
  resources:"risorse",
  and:"e",
  everyday:"ogni giorno.",
  justfewsteps:"Bastano pochi passaggi ed  è fatta.",
  createyourpassword:"Crea la tua password.",
  step:"Passaggio",
  of:"di",
  retry:" Torna al passaggio precedente ",
  almostdone:"Hai quasi finito!",
  checkyourmail:"Controlla la tua email e inserisci il codice di verifica nella casella in basso ",
  addverificationcode:"Aggiungi il codice di verifica",
  securepayment:"Pagamento sicuro via PayPal",
  cancelanytimestep3:"Cancela quando vuoi senza commmissioni o costi nascosti",
  monthlyupdate:"Aggiornamento mensile di servizi e contenuti",
  sendmecode:" Invia codice",
  resendsendmecode:"Invia nuovamente il codice",
  preordersummary:"Il riassunto del tuo preordine.",
  wanttoreward:"Vogliamo premiare tutti i primi DJs e produttori che hanno preordinato il loro accesso a SEEDJ.",
  willgetpremium:"Otterrai un piano Premium allo stesso prezzo del piano di base.",
  changeorcancel:" Cambia o cancella quando vuoi la tua iscrizione senza commissioni extra",
  seatsavailable500:"500 posti disponibili.",
  onepersoneverymembership:" Solo una persona per ogni abbonamento. Il tuo profilo verrà rivisto dalle labels e dalle agenzie di booking.",
  receiveemail:" Il 30 Agosto riceverai una email per accedere alla piattaforma.",
  weeklyupdates:"Riceverai un aggiornamento settimanale riguardo i nuovi partner, contenuti e servizi che troverai la prima volta che accedi alla piattaforma.",
  unlimited:"Illimitato",
  limitbasicplan:"(Limitato per il piano di base:",
  masterclassandtutorials:"Masterclasses & Tutorials",
  course:"CORSI",
  courses:" CORSI",
  librarySidePanelReminderTitle: "Il tuo rimander è stato settato.",
  librarySidePanelUnReminderTitle: "Il tuo rimander è stato rimosso.",
  librarySidePanelReminderDescription: "Ti manderemo una mail quando il corso verrà rilasciato",
  courses5:"5 CORSI )",
  trackuploads:"Caricamento traccia",
  tracks3:"3 tracce )",
  sampleslibrary:"Libreria dei Samples",
  samples30:"30 samples )",
  dailycharts:"Libreria delle charts",
  charts10:"10 charts )",
  labelrequest:"Richieste dalle Lables",
  request1:"1 richiesta )",
  bookingrequest:"Richieste dalle agenzie",
  premium:"Premium*",
  yourlastchance:"* Ultima possibilitá per ottenere i nostri servizi premium a un prezzo ridotto.",
  acceptcreditanddebit:" Accettiamo carte di credto o di debito. Il tuo pagameto verrá elaborato via PayPal, carta di debito o di credito.",
  seatreserved10mins:"Il tuo posto rimmará riservato per ",
  minutes:"minuti",
  gainaccess:"Otterrai l'accesso a SEEDJ a partire dal ",
  date30july:" 30 Agosto 2021. ",
  emailwithdate:" In quel giorno riceverai un'email con le istruzioni per accedere alla piattaforma.",
  subscriptionrenewedmonthly:" La tua iscrizione (€19) sará rinnovata ogni mese a partire dal",
  date30sep:"30 Settembre 2021.",
  onemonthfree:"un mese gratis",
  cancelanytime:"Il tuo pagamento verrá elaborato tramite PayPal e puoi cancellare quando vuoi.",
  receiveconfirmation:" Riceverai un'email di conferma ",
  afterpreorder:"subito dopo aver fatto il preordine.",
  preordernow:"Preorina ora",
  paysecurelypaypal:"Configura il tuo metodo di pagamento.",
  preorderconfirmed:"Il tuo preordine é confermato. Dovresti aver ricevuto un'email di conferma. Ci vediamo ad Agosto!",
  getaspoiler:"Guarda il nostro spoiler",
  done:"Fatto !",
  trackuploadshint:"Carica le tracce che vorresti venissero ascoltate dalle labels partner filtrate in base al tuo miro-genere musicale.",
  masterclassandtutorialshint:"Guarda 150 ore di contenuti registrati dai migliori DJ's e produttori della scena. Ogni mese verranno aggiunte 30 ore di contenuti.",
  sampleslibraryhint:"Scarica piú di 600 samples pronti ad essere usati per le tue produzioni. Ogni mese verranno aggiunti 240 nuovi samples.",
  dailychartshint:" Ottieni l'accesso a centinaia di charts in collaborazione con i media e i DJs di tutto il mondo. Le charts verranno aggiornate giornalmente.",
  labelrequesthint:"Ricevi ed accetta le richieste dalle labels che hanno ascoltato le tue tracce per il rilascio nella loro etichetta.",
  bookingrequesthint:"Ricevi e accetta richieste dalle agenzie di booking per unirsi ai loro rosters. Tieni sempre aggiornato il tuo profilo :-).",
  emailvalidsuccess:"Email registrata con successo.",
  contactstip:"Connettiti direttamente con piu'di 300 partners incluso le agenzie di booking, le etichette discografiche e i DJs di tutto il mondo.",
  emailvaliderror:" Si prega di inserire un'email valida.",
  allfieldmandatory:"Tutti i campi sono obbligatori.",
  validpassword:"Si prega di inserire una password valida.",
  validotp:"Si prega di inserire un OTP valido.",
  passwordtip:"Min. 8 lettere ",
  passwordtip1:"1 lettera maiuscola",
  passwordtip2:"1 Numero ",
  passwordtip3:"1 lettera minuscola ",
  valid:"Valido",
  notvalid:"Non valido",
  month1free:"1 mese gratis",
  month1tip:" La tua iscrizione sará attivata il 30 Agosto e inizierai a pagare l'abbonamento a partire dal 30 Settembre 2021.",
  premiumplan:"Piano Premium",
  yourtimeup:"Il tuo tempo é scaduto. Riprova.",
  notmatching:"Non corrisponde",
  newmasterclasstip:"Guarda sempre nuove Masterclasses e Tutorials dei tuoi DJs e produttori preferiti.",
  resendhinttip:" Se non ottieni il codice di verifica controlla negli spam o richiedi un nuovo codice di verifica nella tua casella di posta.",
  resourcetip:" Ottieni centinaia di charts e samples esclusivi filtrati in base al tuo genere e aggiornati giornalmente.",
  slotnotavailable:"Posti al momento non piú disponibili.",
  matching:"Corrisponde",
  entervaliddetails:"Si prega di inserire i dettagli corretti",
  loadertext:'Attendi un momento per favore...',
  enterpassword:"Inserisci la tua password",
  confirmpassword:"Conferma la tua password",
  login:"Accedi",
  subgenre:"Micro-genere",
  uploadimage:"Carica immagine",
  upload:"Carica",
  filename:"Nome del file",
  duration:"Durata",
  yourtrackbeenuploaded:" La tua traccia é stata caricata con successo.",
  yourepbeenuploaded:"Il tuo EP é stato caricato con successo.",
  uploadnextrack:"Carica una nuova traccia",
  getmetodashboard:"Portami alla dashboard",
  uploadproperresolutionimage:"Si prega di selezionare un'immagine con una risoluzione di almeno 400*400 pixels.",
  uploadcoverimage:"Si prega di selezionare un'immagine con una risoluzione minima di 400*400 pixels",
  supportedprofiles:"Profili supportati",
  thankyou:"Grazie!",
  profilesetup:"Il tuo pagamento é avvenuto con successo, ora e'il momento per configurare il tuo profilo.",
  yourstagename:"Il tuo nome d'arte",
  soundcloudlink:"Soundcloud Link",
  age:"Etá",
  residentadvisorlink:"Resident Advisor Link",
  entercity:"Inserisci la cittá ",
  city:"Cittá",
  country:"Nazione",
  socialmedialink:"Social Media Link (FB/IG)",
  youraffiliationlink:"Il tuo link di affiliazione.",
  maingenre:"Genere principale",
  uploadprofileimage:"Carica la tua immagine del profilo",
  profileupdated:"Il tuo profilo é stato aggiornato con successo.",
  fillrequiredfields:" Si prega di compilare tutti i campi richiesti.",
  fillyourbio:"Compila la tua bio",
  startonboarding:"Inizia la tua onboarding",
  righttalent:"Compila questi campi per aiutarci a trovare i talenti piú adatti a te. ",
  uploadplogo:"Carica il tuo logo",
  skippedtracks:"Tracce saltate",
  saveforlater:"Salva per dopo",
  sendrequest:" Invia una richiesta",
  alreadysent:" Richiesta giá inviata",
  alreadysaved:"Tracce salvate",
  profilesaved:"Profili salvati",
  managername:"Inserisci il nome del manager",
  aboutlesson:"Riguardo questa lezione",
  aboutcourse:"Riguardo questo corso",
  addtofavourites:"Aggiungi ai preferiti",
  removefavouriteS:"Rimuovi dai preferiti",
  becomingaproffesional:" Diventare un professionista",
  myearlycarrer:"La mia carriera passata",
  startacourse:"...o inizia un nuovo corso ",
  upcomingcourses:"PROSSIMI CORSI ",
  starttrackrevision:" INIZIA LA REVISIONE DELLA TRACCIA",
  startyourscouting:"INIZIA IL TUO SCOUTING",
  markassigned:"Segna come rilasciata",
  alreadysigned:"Giá rilasciata",
  signed:"Pubblicata",
  maxsignedtracks:"3 tracce sono giá state rilasciate.",
  tracksaved:"La traccia é stata salvata con successo.",
  requestsent:"Richiesta inviata con successo.",
  enjoynewunreleased:"Goditi le tracce ancora non rilasciate degli altri utenti.",
  playthistrack:"RIPRODUCI QUESTA TRACCIA",
  learnnewskills:"Impara nuove abilitá da tutor professionalmente riconosciuti e costruisci la tua identitá musicale.",
  whatisexcepted:" Cosa vi potete aspettare di vedere in SEEDJ nei prossimi mesi?",
  djing:"DJing",
  producing:"Produzione",
  musicbusiness:"Business Musicale",
  avialablecourses:"Corsi per te",
  support:"Supporta",
  startyour:"Inizia la ",
  session:"sessione.",
  inthisclass:"In questo corso:",
  audioresources:"Risorse audio pronti all'uso per la tua produzione.",
  ourcharts:"La nostra selezone di charts ti offrirá musica sempre nuova da usare nei tuoi sets.",
  trackremoved:"La traccia é stata rimossa con successo.",
  linkselected:"Collega la traccia selezionata:",
  marksignedtext:"Per promuovere questo DJ nel tuo profilo, dovrai segnare la traccia come pubblicata. Per fare ció, devi solo incollare il link della tua traccia in questa casella di testo!",
  subscriptionbilling:"Abbonamento e pagamenti",
  uploadmedia:"Carica Media",
  bio:"Bio",
  billingaddress:"Indirizzo di fatturazione",
  downloadalldocuments:"Scarica i documenti di pagamento",
  unsucsribe:"Disiscriviti",
  tocontinueclass:"Continua a guardare",
  changemail:"Cambia email",
  changepassword:"Cambia password",
  oldemail:"Vecchia email",
  newemail:"Nuova Email",
  oldpassword:"Vecchia password",
  newpassword:"Nuova password",
  confirmnewpassword:"Conferma la nuova password",
  resetpasswordtoast:"La password é stata cambiata con successo",
  update:"Aggiorna",
  save:"Salva",
  cancel:"Cancella",
  email:"Email",
  setupyourbilling:"Imposta i tuoi pagamenti.",
  enjoy2months:"Goditi i primi 2 mesi gratis.",
  addpaymentmethos:"Aggiungi il principale metodo di pagamento.",
  renewalsubscription:"Il primo rinnovo del tuo abbonamento avverá il 30 Ottobre 2021 e il prezzo sará di €19.",
  gotodashboard:"Vai alla dashboard",
  findgenre:"Trova un genere...",
  findsubgenre:"Trova un micro-genere...",
  socialmedialinksetting:"Instagram Link",
  playedthistrack:"Riproduci questa traccia",
  supporttrack:"Supporta questa traccia",
  selectcountry:"Seleziona prima la nazione...",
  homepage: "Homepage",
  seedj2:'Seedj 2.0 è adesso disponibile.',
  freshnewmusic: "Nuova musica",
  likes: "likes",
  try:'Prova adesso.',
  ops: "OOPS!",
  welcome_courses: "Benvenuto nei Corsi!",
  library_courses_hours_pre: "Segui più di",
  library_courses_hours: "150 ore di corsi",
  library_courses_text: "con il tuo tutor preferito",
  library_courses_months: "quando vuoi.",
  samples_header_subtitle: "Trending",
  home_label_review_music: "Start reviewing some fresh music.",
  home_label_title: "Dashboard",
  home_label_reviewed_track: "Reviewed Tracks",
  home_label_signed_track: "Tracce Firmate",
  home_label_remaining_track: "Tracce Rimanenti",
  home_label_subheading: "Stato delle tracce",
  home_label_inner_menu_1: "Manda Richiesta",
  home_label_inner_menu_2: "Salva per dopo",
  home_label_inner_menu_2_variant: "Unsave",
  home_label_inner_menu_3: "Non interessante",
  home_label_inner_menu_4: "Cancella da questa Tab",
  home_agency_review_talent: "Start your scouting for new talents.",
  home_agency_reviewed_profile: "Reviewed Profiles",
  home_agency_signed_profile: "Profili Firmati",
  home_agency_remaining_profile: "Profili Rimanenti",
  sign_in_modal_message_request_title: "Message request.",
  sign_in_modal_message_request_description: "Accept to be able to chat with them. Cancel to reject the request",
  sign_in_modal_message_request_cancel: "Cancel",
  sign_in_modal_message_request_accept: "Accept",
  sign_in_modal_message_delete_title : "Delete chat.",
  sign_in_modal_message_delete_description : "Deleting this conversation cannot be undone.",
  sign_in_modal_message_delete_accept : "Delete chat",
  sign_in_modal_message_delete_cancel : "Cancel",
}
export default it;
