import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getToken = () => {
    // console.log(cookies.get('token'));
    // return cookies.get('token') || null;
    return sessionStorage.getItem('token') || null;
    
  }

  export const getRole = () => {
   return sessionStorage.getItem('role')
   
  }

  export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('checkouttoken');
    cookies.remove("token")
  }

  export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('role', user.role);
    // let date = new Date();
    // cookies.set('token', token);
   
  }

  export const setCheckoutUserSession = (token) => {
    sessionStorage.setItem('checkouttoken', token);
  }

  export const getCheckoutToken = () => {
    // console.log(cookies.get('token'));
    // return cookies.get('token') || null;
    return sessionStorage.getItem('checkouttoken') || null;
    
  }

  export const removeUserKeySession = () => {
    sessionStorage.removeItem('key');
    sessionStorage.removeItem('chefToken');
    sessionStorage.removeItem('role');  
  }
   
  // set the token and user from the session storage
  export const setUserKeySession = (key) => {
    sessionStorage.setItem('key', key);
  }

    export const setFirebaseDeviceToken = (token) => {
    sessionStorage.setItem('devicetoken', token);   
  }
  export const getDeviceToken = () => {
    return sessionStorage.getItem('devicetoken') || null;
    
  }

export const getDaysBetweenDates = (date) => {
    const then = new Date(date);
    const now = new Date();
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
    return daysBetweenDates
} 