import React from "react";
import {
  Routes as RouterDom,
  BrowserRouter as Router,
  Route,
  Navigate
} from "react-router-dom";
import PostTrackandEP from "../components/postTrackEp/posttrackep";
import LandingPage from "../screen/checkoutprocess/landingpage";
import useDeviceAgent from "../utils/hooks/useDeviceAgent";

const Login = React.lazy(() => import("../screen/login/login"));
const PrivateRoute = React.lazy(() => import("../utils/PrivateRoute"));
const PrivateRouteCommon = React.lazy(() => import("../utils/PrivateRouteCommon"));
const PublicRoute = React.lazy(() => import("../utils/PublicRoute"));
const Dashboard = React.lazy(() =>
  import("../screen/panel/dashboard/dashboard")
);
const DashboardAgency = React.lazy(() =>
  import("../screen/panel/dashboardagency/dashboardagency")
);
const SetPassword = React.lazy(() =>
  import("../screen/setpassword/setpassword")
);
const EmailResetPassword = React.lazy(() =>
  import("../screen/emailresetpassword/emailresetpassword")
);
const DashboardLabel = React.lazy(() =>
  import("../screen/panel/dashboardlabel/dashboardlabel")
);
const Forgotpassword = React.lazy(() =>
  import("../screen/forgotpassword/forgotpassword")
);
const CheckoutProcess = React.lazy(() =>
  import("../screen/checkoutprocess/checkout")
);
const CheckoutStepOne = React.lazy(() =>
  import("../screen/checkoutprocess/components/step1checkout/steponecheckout")
);
const CheckoutStepTwo = React.lazy(() =>
  import("../screen/checkoutprocess/components/step2checkout/steptwocheckout")
);
const CheckoutStepFour = React.lazy(() =>
  import("../screen/checkoutprocess/components/step4checkout/stepfourcheckout")
);
const PublicRouteCheckout = React.lazy(() =>
  import("../utils/PublicRouteCheckout")
);
const PrivateRoutesCheckout = React.lazy(() =>
  import("../utils/PrivateRoutesCheckout")
);
const FirstLogin = React.lazy(() => import("../screen/firstlogin/firstlogin"));
const Paymentsetup = React.lazy(() =>
  import("../screen/paymentsetup/paymentsetup")
);
const Thankyoupage = React.lazy(() =>
  import("../screen/thankyoupage/thankyoupage")
);
const Community = React.lazy(() =>
  import("../screen/panel/community/community")
);
const Exclusivesamples = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "../screen/panel/exclusivesamples/exclusivesamples"
  )
);
const Home = React.lazy(() =>
  import(/* webpackPrefetch: true */ "../screen/panel/home/home")
);
const HomeAgency = React.lazy(() =>
  import("../screen/panel/homeagency/homeagency")
);
const HomeLabel = React.lazy(() =>
  import("../screen/panel/homelabel/homelabel")
);
const Library = React.lazy(() =>
  import(/* webpackPrefetch: true */ "../screen/panel/library/library")
);
const Monthlycharts = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "../screen/panel/monthlycharts/monthlycharts"
  )
);
const Profile = React.lazy(() =>
  import(/* webpackPrefetch: true */ "../screen/panel/profile/profile")
);
const Profileagencylabel = React.lazy(() =>
  import("../screen/panel/profileagencylabel/profileagencylabel")
);
const Signrequest = React.lazy(() =>
  import("../screen/panel/signrequest/signrequest")
);
const Masterclass = React.lazy(() =>
  import(/* webpackPrefetch: true */ "../screen/panel/masterclass/masterclass")
);
const Settings = React.lazy(() => import("../screen/panel/settings/settings"));
const Support = React.lazy(() => import("../screen/panel/support/support"));
const Singlechart = React.lazy(() =>
  import("../screen/panel/singlechart/singlechart")
);
const HomeMobile = React.lazy(() =>
  import("../screen/panel/home/mobile/homeMobile")
);
const Checkout2 = React.lazy(() => import("../screen/checkout2.0/checkout"));

export default function Routes() {
  const [deviceType] = useDeviceAgent();
  // const navigate = useNavigate()

  /* const historyanalytics = createBrowserHistory()

    ReactGA.initialize('G-N0SKW0J4FZ', {
        gaOptions: {
            siteSpeedSampleRate: 100,
        }
    });

    historyanalytics.listen((location, action) => {
        ReactGA.pageview(location.pathname + location.search);
    });*/
  const UserPanel = [
    {
      path: "home",
      exact: true,
      // render: deviceType !== 'mobile' ? <Home/> :  <HomeMobile/>,
      render: <Home />,
    },
    {
      path: "community",
      exact: true,
      render: <Community />,
    },
    {
      path: "signrequest",
      exact: true,
      render: <Signrequest />,
    },
    {
      path: "library",
      exact: true,
      render: <Library />,
    },
    {
      path: "profile",
      exact: true,
      render: <Profile />,
    },
    {
      path: "postatrack",
      exact: true,
      render: <PostTrackandEP />,
    },
    {
      path: "monthlycharts",
      exact: true,
      render: <Monthlycharts />,
    },
    {
      path: "exclusivesamples",
      exact: true,
      render: <Exclusivesamples />,
    },
    {
      path: "masterclass",
      exact: true,
      render: <Masterclass />,
    },
    {
      path: "settings",
      exact: true,
      render: <Settings type="user" />,
    },
    {
      path: "support",
      exact: true,
      render: <Support />,
    },
    {
      path: "singlechart",
      exact: true,
      render: <Singlechart />,
    },
  ];

  const AgencyPanel = [
    {
      path: "signrequestagency",
      exact: true,
      render: <Signrequest />,
    },
    {
      path: "profileagency",
      exact: true,
      render: <Profileagencylabel type="agency" />,
    },
    {
      path: "homeagency",
      exact: true,
      render: <HomeAgency />,
    },
    {
      path: "settingsagency",
      exact: true,
      render: <Settings type="agency" />,
    },
    {
      path: "profile",
      exact: true,
      render: <Profile />,
    },
  ];

  const LabelPanel = [
    {
      path: "signrequestlabel",
      exact: true,
      render: <Signrequest />,
    },
    {
      path: "profilelabel",
      exact: true,
      render: <Profileagencylabel type="label" />,
    },
    {
      path: "homelabel",
      exact: true,
      render: <HomeLabel />,
    },
    {
      path: "settingslabel",
      exact: true,
      render: <Settings type="label" />,
    },
    {
      path: "profile",
      exact: true,
      render: <Profile />,
    },
  ];

  return (
    <React.Suspense fallback={null}>
      <Router>
        <RouterDom>
          <Route
            path="/"
            element={
              <PrivateRoutesCheckout>
                <CheckoutProcess />
              </PrivateRoutesCheckout>
            }
          />
           <Route
            path="*"
            element={
              <Navigate to="/" />
            }
          />
          <Route
            path="/promo"
            element={
              <PublicRouteCheckout>
                <LandingPage />
              </PublicRouteCheckout>
            }
          />
          <Route
            path="/checkoutsetpassword"
            element={
              <PrivateRouteCommon>
                <CheckoutStepOne />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/checkout2.0"
            element={
              <PublicRoute>
                <Checkout2 />
              </PublicRoute>
            }
          />
          <Route
            path="/chooseplan"
            element={
              <PrivateRouteCommon>
                <CheckoutStepTwo />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/checkoutpayment"
            element={
              <PrivateRouteCommon>
                <CheckoutStepFour />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/emailresetpassword"
            element={
              <PublicRoute>
                <EmailResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/updateprofile"
            element={
              <PrivateRouteCommon>
                <FirstLogin />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/paymentsetup"
            element={
              <PrivateRouteCommon>
                <Paymentsetup />
              </PrivateRouteCommon>
            }
          />

          <Route
            path="/forgotpassword"
            element={
              <PublicRoute>
                <Forgotpassword />
              </PublicRoute>
            }
          />
          <Route
            path="/signrequest"
            element={
              <PrivateRouteCommon>
                <Dashboard />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/Dashboard"
            element={
              <PrivateRouteCommon>
                <DashboardLabel />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/support"
            element={
              <PrivateRouteCommon>
                <Dashboard />
              </PrivateRouteCommon>
            }
          />
          <Route
            path="/resetpassword"
            element={
              <PublicRoute>
                <SetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/thankyou"
            element={
              <PublicRoute>
                <Thankyoupage />
              </PublicRoute>
            }
          />
           <Route path={"/user"} element={<Dashboard />}>
            {UserPanel.map((route) => (
              <Route
                path={route.path}
                element={<PrivateRoute path={"user"} >{route.render}</PrivateRoute>}
              />
            ))}
          </Route>

            <Route path={"/label"} element={<DashboardLabel />}>
            {LabelPanel.map((route) => (
              <Route
                path={route.path}
                element={<PrivateRoute path={"label"}>{route.render}</PrivateRoute>}
              />
            ))}
          </Route>
       
         
          <Route path={"/agency"} element={<DashboardAgency />}>
            {AgencyPanel.map((route) => (
              <Route
                path={route.path}
                element={<PrivateRoute  path={"user"}>{route.render}</PrivateRoute>}
              />
            ))}
          </Route>
        
        </RouterDom>
      </Router>
    </React.Suspense>
  );
}
